<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        Empleados
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical>
                    </v-divider>
                    <v-spacer>
                    </v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer>
                    </v-spacer>
                    <v-form ref="form" lazy-validation>
                        <v-dialog v-model="dialog" width="1100px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card :loading="loading">
                                <template slot="progress">
                                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                                </template>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.name"
                                                    @input="editedItem.name = editedItem.name.toUpperCase()"
                                                    label="Nombre" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.last_name"
                                                    @input="editedItem.last_name = editedItem.last_name.toUpperCase()"
                                                    label="Primer Apellido" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.mothers_last_name"
                                                    @input="editedItem.mothers_last_name = editedItem.mothers_last_name.toUpperCase()"
                                                    label="Segundo Apellido" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.curp"
                                                    @input="editedItem.curp = editedItem.curp.toUpperCase()"
                                                    label="CURP" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.rfc"
                                                    @input="editedItem.rfc = editedItem.rfc.toUpperCase()" label="RFC"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.ine"
                                                    @input="editedItem.ine = editedItem.ine.toUpperCase()" label="INE">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.security_number"
                                                    @input="editedItem.security_number = editedItem.security_number.toUpperCase()"
                                                    label="NSS" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-dialog max-width="400px" persistent v-model="fecha_inicio">
                                                    <template v-slot:activator="{ on: { click } }">
                                                        <v-text-field v-on:click="click" v-model="editedItem.entry_date"
                                                            label="Fecha de comienzo" required>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="calendario" scrollable actions>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="primary" @click="fecha_inicio=false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn color="primary" @click="colocarFecha">
                                                                OK
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-autocomplete :items="tipos_sangre" item-text="blood_types"
                                                    item-value="id" v-model="editedItem.blood_type_id"
                                                    label="Tipo de sangre" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-autocomplete :items="tipos_empleado" item-text="employee_type"
                                                    item-value="id" v-model="editedItem.employee_type_id"
                                                    label="Tipo de Empleado" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-autocomplete :items="departamentos" item-text="department"
                                                    item-value="id" v-model="editedItem.departamnt_id"
                                                    label="Departamento" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-autocomplete :items="posiciones" item-text="position" item-value="id"
                                                    v-model="editedItem.position_id" label="Posición"
                                                    :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.street"
                                                    @input="editedItem.street = editedItem.street.toUpperCase()"
                                                    label="Calle" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.outdoor_number"
                                                    @input="editedItem.outdoor_number = editedItem.outdoor_number.toUpperCase()"
                                                    label="No. Exterior" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.interior_number"
                                                    @input="editedItem.interior_number = editedItem.interior_number.toUpperCase()"
                                                    label="No. Interior">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.suburb"
                                                    @input="editedItem.suburb = editedItem.suburb.toUpperCase()"
                                                    label="Colonia" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-autocomplete :items="estados" item-text="state" item-value="id"
                                                    v-model="editedItem.state_id" label="Estado" :rules=rules_validar
                                                    @change="filtrarMunicipios">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-autocomplete :items="municipios" item-text="city" item-value="id"
                                                    v-model="editedItem.city_id" label="Municipio" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.payroll_number"
                                                    @input="editedItem.payroll_number = editedItem.payroll_number.toUpperCase()"
                                                    label="No. Nómina">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.base_salary"
                                                    @input="editedItem.base_salary = editedItem.base_salary.toUpperCase()"
                                                    label="Salario Base" :rules=rules_validar type="number">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-autocomplete :items="metodos_pago" item-text="payment_type"
                                                    item-value="id" v-model="editedItem.payment_type_id"
                                                    label="Métodos de Pago" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.email" label="Correo Electrónico"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.phone_number"
                                                    label="Numero de Teléfono" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title class="text-h5">¿Estas seguro de eliminar?</v-card-title>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK
                                </v-btn>
                                <v-spacer>
                                </v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon color="closeSesion" small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarEmpleados()">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <template>
            <v-snackbar v-model="alert" :timeout="tiempo_muestra" :value="true" :color="color_alerta" absolute right
                rounded="pill">
                <v-icon class="mr-3">
                    {{icono_alerta}}
                </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Empleados'
    },
    data: () => ({
        tiempo_muestra: 1500,
        url_ruta: "employee",
        alert: false,
        texto_alerta: "",
        icono_alerta: "",
        color_alerta: "",
        fecha_inicio: false,
        calendario: null,
        dialog: false,
        disBoton: false,
        loading: false,
        dialogDelete: false,
        search: "",
        loader: true,
        encabezados: [
            {
                text: 'Clave',
                value: 'id',
            },
            {
                text: 'Nombre',
                value: 'name',
            },
            {
                text: 'RFC',
                value: 'rfc',
            },
            {
                text: 'Correo',
                value: 'email',
            },
            {
                text: 'Telefono',
                value: 'phone_number',
            },
            {
                text: 'Estado',
                value: 'state',
            },
            {
                text: 'Ciudad',
                value: 'city',
            },

            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        detalles: [],
        editedIndex: -1,
        editedItem: {
            id: 0,
            payroll_number: '',
            name: '',
            last_name: '',
            mothers_last_name: '',
            curp: '',
            rfc: '',
            ine: '',
            entry_date: '',
            security_number: '',
            blood_type_id: '',
            payment_type_id: '',
            city_id: '',
            state_id: '',
            employee_type_id: '',
            departamnt_id: '',
            position_id: '',
            suburb: '',
            street: '',
            outdoor_number: '',
            interior_number: '',
            base_salary: '',
            email: '',
            phone_number: '',
        },
        defaultItem: {
            id: 0,
            payroll_number: '',
            name: '',
            last_name: '',
            mothers_last_name: '',
            curp: '',
            rfc: '',
            ine: '',
            entry_date: '',
            security_number: '',
            blood_type_id: '',
            payment_type_id: '',
            city_id: '',
            state_id: '',
            employee_type_id: '',
            departamnt_id: '',
            position_id: '',
            suburb: '',
            street: '',
            outdoor_number: '',
            interior_number: '',
            base_salary: '',
            email: '',
            phone_number: '',
        },
        rules_validar: [(v) => !!v || "Campo requerido"],
        estados: [],
        tipos_sangre: [],
        tipos_empleado: [],
        posiciones: [],
        metodos_pago: [],
        departamentos: [],
        municipios_servidor: [],
        municipios: []
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Empleado' : 'Modificar Empleado'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.consultarEmpleados()
        this.consultarEstados()
        this.consultarMunicipios()
        this.consultarDepartamentos();
        this.consultarMetodosPago();
        this.consultarPosiciones();
        this.consultarTiposEmpleado();
        this.consultarTiposSangre();
    },

    methods: {
        consultarEmpleados() {
            this.loader = true;
            service.consultarDatos(this.url_ruta).then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.detalles = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problema al consultar empleados:" + error);
                });
        },

        colocarFecha() {
            this.editedItem.entry_date = this.calendario;
            this.fecha_inicio = false;
        },

        consultarEstados() {
            this.loader = true;
            service
                .consultarEstados()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.estados = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Estados");
                });
        },

        consultarTiposSangre() {
            this.loader = true;
            service.consultarTiposSangre().then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.tipos_sangre = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problema al consultar los tipos de sangre" + error);
                })
        },

        consultarMunicipios() {
            this.loader = true;
            service
                .consultarMunicipios()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.municipios_servidor = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Municipios");
                });
        },

        consultarTiposEmpleado() {
            this.loader = true;
            service.consultarTiposEmpleado().then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.tipos_empleado = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problemas al consultar tipos de empleado:" + error);
                });
        },

        consultarPosiciones() {
            this.loader = true;
            service.consultarPosiciones().then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.posiciones = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problemas al consultar las posiciones:" + error);
                });
        },

        consultarMetodosPago() {
            this.loader = true;
            service.consultarMetodosPago().then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.metodos_pago = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problemas al consultar métodos de pago: " + error);
                });
        },

        consultarDepartamentos() {
            this.loader = true;
            service.consultarDepartamentos().then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.departamentos = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problemas al consultar los departamentos: " + error);
                });
        },

        editItem(item) {
            this.municipios = [];
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
            this.filtrarMunicipios();
            this.$refs.form.resetValidation();
        },

        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.disBoton = true;
            this.loading = true;
            service
                .eliminarDatos(this.url_ruta, this.editedItem, this.editedItem.id)
                .then((response) => {
                    if (response.data.response_flag === 1) {
                        this.consultarEmpleados();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta = "Se ha eliminado con éxito el empleado"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if (response.data.response_flag !== 1 || response.data === null) {
                        this.consultarEmpleados();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta = "Ocurrió un error al eliminar el empleado"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar empleados");
                });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.disBoton = true;
                if (this.editedIndex > -1) {
                    service
                        .modificarDatos(this.url_ruta, this.editedItem, this.editedItem.id)
                        .then((response) => {
                            if (response.data.response_flag === 1) {
                                this.consultarEmpleados();
                                this.loading = false;
                                this.disBoton = false;
                                this.close();
                                this.texto_alerta = "Se ha modificado con éxito el empleado"
                                this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                                this.color_alerta = "success";
                                this.alert = true;
                            }
                            else if (response.data.response_flag !== 1 || response.data === null) {
                                this.consultarEmpleados();
                                this.loading = false;
                                this.disBoton = false;
                                this.close();
                                this.texto_alerta = "Ocurrió un error al modificar el empleado"
                                this.icono_alerta = "mdi-close-circle";
                                this.color_alerta = "red darken-2";
                                this.alert = true;
                            }
                        })
                        .catch((error) => {
                            console.log("Problema al consultar empleados" + error);
                        })
                } else {
                    service
                        .ingresarDatos(this.url_ruta, this.editedItem)
                        .then((response) => {
                            if (response.data.response_flag === 1) {
                                this.consultarEmpleados();
                                this.loading = false;
                                this.disBoton = false;
                                this.close();
                                this.texto_alerta = "Se ha guardado con éxito el empleado"
                                this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                                this.color_alerta = "success";
                                this.alert = true;
                            }
                            else if (response.data.response_flag !== 1 || response.data === null) {
                                this.consultarEmpleados();
                                this.loading = false;
                                this.disBoton = false;
                                this.close();
                                this.texto_alerta = "Ocurrió un error al guardar el empleado" + response.data.response;
                                this.icono_alerta = "mdi-close-circle";
                                this.color_alerta = "red darken-2";
                                this.alert = true;
                            }
                        })
                        .catch(() => {
                            console.log("Problema al consultar empleados");
                        });
                }
            }
        },

        filtrarMunicipios() {
            this.municipios = this.municipios_servidor.filter(municipio => municipio.state_id == this.editedItem.state_id);
        },

        uppercase() {
            this.code = this.code.toUpperCase();
        }
    },
}
</script>