import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#00838f',
        secondary: '#005662',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        closeSesion: '#FF0000',
        textWhite: '#FFFFFF',
        buttonEdit: "#55A4B6",
        buttonThird: "#47ED56",
        buttonFourth: "#EEE33B"
      },
      dark: {
        primary: "#FFC107",
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
});
