<template>
  <router-view> </router-view>
</template>

<script>
export default {
  data() {
    return{};
  },
};
</script>
<style>
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  background-color: #aebfbe;
  color: rgba(0, 0, 0, 1);
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(odd){
    background-color: #e0f2f1; 
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(even){
    background-color: #fff; 
  }

  .theme--light.v-data-table .v-data-footer{
    background-color: #aebfbe;
  }
</style>