<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Turnos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref="form"  lazy-validation>
                        <v-dialog v-model="dialog" width="700px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card :loading="loading">
                                <template slot="progress">
                                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                                </template>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.turn" @input="editedItem.turn = editedItem.turn.toUpperCase()" label="Turno"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-time-picker v-model="editedItem.start_time" :rules=rules_validar ampm-in-title></v-time-picker>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-time-picker v-model="editedItem.end_time" :rules=rules_validar ampm-in-title></v-time-picker>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title class="text-h5">¿Estas seguro de eliminar?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon color="closeSesion" small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarTurnos">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <template>
            <v-snackbar
                v-model = "alert"
                :timeout="tiempo_muestra"
                :value="true"
                :color = "color_alerta"
                absolute
                right
                rounded="pill"
            >
            <v-icon
                class="mr-3"
            >
                {{icono_alerta}}
            </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Turnos'
    },
    data: () => ({
        tiempo_muestra:1500,
        url_ruta:"turn",
        alert: false,
        texto_alerta:"",
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        disBoton: false,
        loading: false,
        dialogDelete: false,
        search: "",
        loader: true,
        encabezados: [
            {
                text: 'Turno',
                value: 'turn',
            },
            {
                text: 'Fecha de Inicio',
                value: 'start_time',
            },
            {
                text: 'Fecha de Termino',
                value: 'end_time',
            },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        detalles: [],
        editedIndex: -1,
        editedItem: {
            id: 0,
            turn: '',
            start_time: '',
            end_time: '',
        },
        defaultItem: {
            id: 0,
            turn: '',
            start_time: '',
            end_time: '',
        },
        rules_validar: [(v) => !!v || "Campo requerido"]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Turno' : 'Modificar Turno'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.consultarTurnos()
    },

    methods: {
        consultarTurnos() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar turnos");
                });
        },

        editItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.disBoton = true;
            this.loading = true;
            service
                .eliminarDatos(this.url_ruta, this.editedItem , this.editedItem.id)
                .then((response) => {
                    if(response.data.response_flag === 1){
                        this.consultarTurnos();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Se ha eliminado con éxito el turno"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if(response.data.response_flag !== 1 || response.data === null){
                        this.consultarTurnos();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Ocurrió un error al eliminar el turno"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar turnos");
                });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.disBoton = true;
                if (this.editedIndex > -1) {
                    service
                        .modificarDatos(this.url_ruta, this.editedItem,this.editedItem.id)
                        .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarTurnos();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha modificado con éxito el turno"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarTurnos();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar el turno"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                    .catch(() => {
                        console.log("Problema al consultar turnos");
                    });
                } else {
                    service
                        .ingresarDatos(this.url_ruta,this.editedItem)
                        .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarClientes();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha guardado con éxito el turno"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarClientes();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al guardar el turno"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                        .catch(() => {
                            console.log("Problema al consultar turnos");
                        });
                }
            }
        },
    },
}
</script>