<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Ordenes de Planeación</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref="form" lazy-validation>
                        <v-dialog v-model="dialog" width="1300px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card :loading="loading">
                                <template slot="progress">
                                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                                </template>
                                <v-card-title>
                                    <span class="text-h5">{{titulo}}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-autocomplete :items="areas_trabajo" item-text="work_area"
                                                    item-value="id" v-model="editedItem.work_area_id"
                                                    label="Area de Trabajo" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-autocomplete :items="lineas_produccion" item-text="production_line"
                                                    item-value="id" v-model="editedItem.production_line_id"
                                                    label="Linea de Producción" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-autocomplete :items="maquinas" item-text="machine" item-value="id"
                                                    v-model="editedItem.machine_id"
                                                    label="Estación de Trabajo / Maquina" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.start_date" :rules=rules_validar
                                                    label="Fecha Inicio" type="date" required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.start_time" :rules=rules_validar
                                                    label="Tiempo Inicio" type="time" required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.expected_end_date"
                                                    :rules=rules_validar label="Fecha Termino" type="date" required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.expected_end_time"
                                                    :rules=rules_validar label="Tiempo Termino" type="time" required>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="3" md="3">
                                                <v-autocomplete :items="piezas" item-text="piece" item-value="id"
                                                    :disabled="disBoton" v-model="pieza_seleccionada" return-object
                                                    label="Pieza" :error-messages="error_messages">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="cantidad_seleccionada" :disabled="disBoton"
                                                    label="Cantidad de Producción" type="number"
                                                    :error-messages="error_messages_cantidad">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1" md="1">
                                                <v-btn class="mx-2" fab dark color="primary" :disabled="disBoton"
                                                    @click="agregarDetalle">
                                                    <v-icon dark>
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-data-table :headers="encabezados_detalle"
                                                    :items="detalles_orden_produccion" class="elevation-1">
                                                    <template v-slot:item.actions>
                                                        <v-icon small :disabled="disBoton">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog = false">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="dialog_save = true" :disabled="disBoton">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{item}">
                <v-icon color="buttonThird" class="mr-2" @click="consultarDetalle(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarOrdenes">
                    Consultar
                </v-btn>
            </template>
            <template v-slot:item.status>
                <v-chip color="green" dark>
                    Activa
                </v-chip>
            </template>
        </v-data-table>
        <template>
            <v-snackbar v-model="alert" :timeout="tiempo_muestra" :value="true" :color="color_alerta" absolute right
                rounded="pill">
                <v-icon class="mr-3">
                    {{icono_alerta}}
                </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
        <v-dialog v-model="dialog_save" max-width="500px" persistent>
            <v-card :loading="loading">
                <template slot="progress">
                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                </template>
                <v-card-title class="text-h5">¿Quieres guardar la orden de producción?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog_save = false" :disabled="disBoton">Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_info" persistent max-width="1500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{titulo}}</span>
                </v-card-title>
                <v-card-text>
                    <v-card-actions class="justify">
                        <v-tabs>
                            <v-tab @click="step = 1">Información General</v-tab>
                            <v-tab @click="step = 2">Graficos</v-tab>
                        </v-tabs>
                    </v-card-actions>
                    <v-container>
                        <v-window v-model="step">
                            <v-window-item :value="1">
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-autocomplete :items="areas_trabajo" item-text="work_area" item-value="id"
                                            v-model="editedItem.work_area_id" label="Area de Trabajo" readonly
                                            :rules=rules_validar>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-autocomplete :items="lineas_produccion" item-text="production_line"
                                            item-value="id" v-model="editedItem.production_line_id"
                                            label="Linea de Producción" readonly :rules=rules_validar>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-autocomplete :items="maquinas" item-text="machine" item-value="id"
                                            v-model="editedItem.machine_id" label="Estación de Trabajo / Maquina"
                                            readonly :rules=rules_validar>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                        <v-text-field v-model="editedItem.start_date" :rules=rules_validar
                                            label="Fecha Inicio" type="date" required readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                        <v-text-field v-model="editedItem.start_time" :rules=rules_validar
                                            label="Tiempo Inicio" type="time" required readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                        <v-text-field v-model="editedItem.expected_end_date" :rules=rules_validar
                                            label="Fecha Termino" type="date" required readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                        <v-text-field v-model="editedItem.expected_end_time" :rules=rules_validar
                                            label="Tiempo Termino" type="time" required readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-data-table :headers="encabezados_detalle_info"
                                            :items="detalles_orden_produccion" class="elevation-1">
                                            <template v-slot:item.progress="{item}">
                                                <v-progress-linear :color=obtenerColorPorcentaje(item) height="10"
                                                    :value=obtenerPorcenctaje(item) striped>
                                                    <template v-slot:default="{ value }">
                                                        <strong>{{ Math.ceil(value) }}%</strong>
                                                    </template>
                                                </v-progress-linear>
                                            </template>
                                            <template v-slot:item.quantity_incorrect="{item}">
                                                <v-chip color="closeSesion" class="white--text">
                                                    {{item.quantity_incorrect}}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.scrap="{item}">
                                                <v-progress-linear :color=obtenerColorPorcentajeScrap(item) height="10"
                                                    :value=obtenerPorcenctajeScrap(item) striped>
                                                    <template v-slot:default="{ value }">
                                                        <strong>{{ Math.ceil(value) }}%</strong>
                                                    </template>
                                                </v-progress-linear>
                                            </template>
                                            <template v-slot:item.proyection="{ item }">
                                                <v-chip :color="getColor(item)" dark>
                                                    {{ item.proyection }}
                                                </v-chip>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-window-item>
                            <v-window-item :value="2">
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <Bar :chart-options="chartOptionPiezasRealizadas"
                                            :chart-data="chartDataPiezasRealizadas" chart-id="bar-chart"
                                            :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                                            :styles="styles" :width="width" :height="height" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <Pie :chart-options="chartOptionPiezasRealizadas"
                                            :chart-data="chartDataPorcentajesPiezas" chart-id="pie-chart"
                                            :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                                            :styles="styles" :width="width" :height="height" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <LineChartGenerator :chart-options="chartOptionPiezasRealizadas"
                                            :chart-data="chartDataDiasPiezas" chart-id="bar-chart"
                                            :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                                            :styles="styles" :width="width" :height="height" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <Bar :chart-options="chartOptionPiezasRealizadas"
                                            :chart-data="chartDataPiezasEmpleados" chart-id="pie-chart"
                                            :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                                            :styles="styles" :width="width" :height="height" />
                                    </v-col>
                                </v-row>
                            </v-window-item>
                        </v-window>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog_info = false">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import service from "@/core/Service.js";
import { Bar } from 'vue-chartjs/legacy'
import { Pie } from 'vue-chartjs/legacy'

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LineElement,
    LinearScale,
    ArcElement,
    PointElement
} from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement, PointElement)

export default {
    metaInfo: {
        title: 'Ordenes de Planeación'
    },
    components: {
        Bar,
        Pie,
        LineChartGenerator,
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 300
        },
        height: {
            type: Number,
            default: 300
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        url_ruta: 'production_order',
        alert: false,
        texto_alerta: "",
        step: 1,
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        dialog_info: false,
        titulo: "Generar Orden de Planeación",
        dialog_detalle: false,
        dialogDelete: false,
        disBoton: false,
        tiempo_muestra: 1500,
        fecha_inicio_dialog: false,
        fecha_termino_dialog: false,
        hora_inicio: false,
        hora_termino: false,
        loading: false,
        disabled: false,
        search: "",
        fecha_inicio: "",
        fecha_termino: "",
        dialog_save: false,
        tiempo: "",
        loader: true,
        encabezados: [
            {
                text: 'Codigo',
                value: 'id',
            },
            {
                text: 'Area de Trabajo',
                value: 'work_area',
            },
            {
                text: 'Linea de Producción',
                value: 'production_line',
            },
            {
                text: 'Maquina',
                value: 'machine',
            },
            {
                text: 'Fecha de Inicio',
                value: 'start_date',
            },
            {
                text: 'Hora de Inicio',
                value: 'start_time',
            },
            {
                text: 'Fecha de Termino Esperada',
                value: 'expected_end_date',
            },
            {
                text: 'Hora de Termino',
                value: 'expected_end_time',
            },

            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        encabezados_detalle: [
            { text: 'Clave', value: 'code', },
            { text: 'Pieza', value: 'piece' },
            { text: 'Unidad de Medida', value: 'unit' },
            { text: 'Categoria', value: 'piece_category' },
            { text: 'Cantidad de Producción', value: 'quantity', },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        encabezados_detalle_info: [
            { text: 'Clave', value: 'code', },
            { text: 'Pieza', value: 'piece' },
            { text: 'Unidad de Medida', value: 'unit', width: '5%' },
            { text: 'Categoria', value: 'piece_category' },
            { text: 'Cantidad de Producción', value: 'quantity', width: '5%' },
            { text: 'Cantidad Correcta', value: 'quantity_correct', width: '5%' },
            { text: 'Cantidad Incorrecta', value: 'quantity_incorrect', width: '5%' },
            { text: 'Progreso', value: 'progress', width: '10%' },
            { text: 'SCRAP', value: 'scrap', width: '10%' },
            { text: 'P/M', value: 'quantity_evaluated_minute' },
            { text: 'P/P', value: 'proyection' },
        ],
        chartDataDiasPiezas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Producidas',
                    backgroundColor: '#bdbdbd',
                    data: []
                },
                {
                    label: 'SCRAP',
                    backgroundColor: '#ff5f52',
                    data: []
                }
            ]
        },
        detalles: [],
        detalles_orden_produccion: [],
        editedIndex: -1,
        editedItem: {
            id: 0,
            work_area_id: 0,
            production_line_id: 0,
            machine_id: 0,
            piece_id: 0,
            quantity: 0,
            start_date: '',
            expected_end_date: '',
            start_time: '',
            expected_end_time: ''
        },
        detalle_seleccionado: {
            expected_quantity: 0,
            start_date: 0,
            end_date: 0,
            start_time: 0,
            end_time: 0,
        },
        detalle_seleccionado_index: -1,
        defaultItem: {
            id: 0,
            work_area_id: 0,
            production_line_id: 0,
            machine_id: 0,
            piece_id: 0,
            quantity: 0,
            start_date: '',
            expected_end_date: '',
            start_time: '',
            expected_end_time: ''
        },
        rules_validar: [(v) => !!v || "Campo requerido"],
        rules_validar_cantidad: [(v) => !!v || "Campo requerido", (v) => v.length > 0 || "La cantidad debe ser mayor a 0"],
        areas_trabajo: [],
        lineas_produccion: [],
        maquinas: [],
        piezas: [],
        error_messages: [],
        error_messages_cantidad: [],
        pieza_seleccionada: null,
        cantidad_seleccionada: 0,
        chartDataPiezasRealizadas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: '#00838f',
                    data: []
                },
                {
                    label: 'SCRAP',
                    backgroundColor: '#ff5f52',
                    data: []
                }
            ]
        },
        chartDataPiezasEmpleados: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: '#00838f',
                    data: []
                },
                {
                    label: 'SCRAP',
                    backgroundColor: '#ff5f52',
                    data: []
                }
            ]
        },
        chartOptionPiezasRealizadas: {
            responsive: true,
            maintainAspectRatio: false
        },
        chartDataPorcentajesPiezas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: ['#00838f', '#E46651', '#00D8FF', '#DD1B16'],
                    data: []
                }
            ]
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva Area de Trabajo' : 'Modificar Area de Trabajo'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.inicializarComponente()
    },

    methods: {
        inicializarComponente() {
            this.inicializarFechas()
            this.consultarOrdenes()
            this.consultarAreas()
            this.consultarLineas()
            this.consultarMaquinas()
            this.consultarPieza()
        },

        consultarOrdenes() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las areas de trabajo");
                });
        },
        consultarOrdenesEvaluaciones() {
            this.loading = true;
            service
                .ingresarDatos("production_order_evaluation_order", this.editedItem)
                .then((response) => {
                    if (response.data.response_flag == 1) {
                        this.loading = false;
                        this.configurarPiezasTotales(response.data.response);
                        this.configurarPiezasTurnosTotales(response.data.response);
                        this.configurarPiezasDiasTotales(response.data.response);
                        this.configurarPiezasEmpleadosTotales(response.data.response);
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar las areas de trabajo");
                });
        },
        getColor(item) {
            let quantity = Number(item.quantity);
            let proyection = Number(item.proyection);
            let quantity_correct = Number(item.quantity_correct);

            if ((proyection + quantity_correct) < quantity) return 'red'
            else return 'green'
        },
        configurarPiezasTotales(response) {

            let piezas_seleccionadas = new Array();
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let pieza_index_seleccionada = piezas_seleccionadas.findIndex(element => element.piece_id == evaluacion_seleccionada.piece_id);
                //Si existe incrementamos la cantidad realizada
                if (pieza_index_seleccionada > -1) {
                    piezas_seleccionadas[pieza_index_seleccionada].cantidad_realizada = (Number(piezas_seleccionadas[pieza_index_seleccionada].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                    piezas_seleccionadas[pieza_index_seleccionada].cantidad_no_realizada = (Number(piezas_seleccionadas[pieza_index_seleccionada].cantidad_no_realizada) + Number(evaluacion_seleccionada.quantity_incorrect));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    evaluacion_seleccionada.cantidad_no_realizada = evaluacion_seleccionada.quantity_incorrect;
                    piezas_seleccionadas.push(evaluacion_seleccionada);
                }
            });

            this.chartDataPiezasRealizadas.labels = []
            this.chartDataPiezasRealizadas.datasets[0].data = []
            this.chartDataPiezasRealizadas.datasets[1].data = []

            piezas_seleccionadas.forEach(pieza_seleccionada => {
                this.chartDataPiezasRealizadas.labels.push(pieza_seleccionada.piece);
                this.chartDataPiezasRealizadas.datasets[0].data.push(pieza_seleccionada.cantidad_realizada);
                this.chartDataPiezasRealizadas.datasets[1].data.push(pieza_seleccionada.cantidad_no_realizada);
            });
        }
        ,
        consultarAreas() {
            this.loader = true;
            service
                .consultarDatos('work_area')
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.areas_trabajo = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las areas de trabajo");
                });
        },

        consultarLineas() {
            this.loader = true;
            service
                .consultarDatos('production_line')
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.lineas_produccion = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las linea de produccion");
                });
        },

        consultarMaquinas() {
            this.loader = true;
            service
                .consultarDatos('machine')
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.maquinas = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las linea de produccion");
                });
        },
        consultarPieza() {
            this.loader = true;
            service
                .consultarDatos('piece')
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.piezas = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las linea de produccion");
                });
        },

        consultarDetalles() {
            this.loading = true;
            service
                .buscarDatos(this.url_ruta, this.editedItem.id)
                .then((response) => {
                    this.loading = false;
                    if (response.data.response_flag == 1) {
                        this.detalles_orden_produccion = response.data.response;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    console.log("Problema al consultar las linea de produccion");
                });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.disBoton = false;
                this.editedIndex = -1
                this.titulo = "Generar Orden de Planeación"
                this.pieza_seleccionada = null;
                this.cantidad_seleccionada = 0;
                this.error_messages = []
                this.error_messages_cantidad = []
                this.detalles_orden_produccion = []
                this.inicializarFechas()
            })
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.disBoton = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1

            })
            this.$refs.form.resetValidation();
        },

        colocarFecha() {
            this.editedItem.start_date = this.fecha_inicio;
            this.fecha_inicio_dialog = false;
        },

        colocarFechaTermino() {
            this.editedItem.expected_end_date = this.fecha_termino;
            this.fecha_termino_dialog = false;
        },

        obtenerColorPorcentaje(item) {
            let color = "blue"
            let porcentaje = this.obtenerPorcenctaje(item);
            if (porcentaje <= 25) {
                color = "red";
            } else if (porcentaje <= 60) {
                color = "yellow";
            } else {
                color = "green";
            }

            return color;
        },
        obtenerColorPorcentajeScrap(item) {
            let color = "blue"
            let porcentaje = this.obtenerPorcenctajeScrap(item);
            if (porcentaje <= 10) {
                color = "green";
            } else if (porcentaje <= 15) {
                color = "yellow";
            } else {
                color = "red";
            }

            return color;
        },
        obtenerPorcenctaje(item) {
            return (Number(item.quantity_correct) * 100) / Number(item.quantity);
        },
        obtenerPorcenctajeScrap(item) {
            let sumatoria_realizada = (Number(item.quantity_correct_detail) + Number(item.quantity_incorrect_detail));
            console.log(sumatoria_realizada);
            return (Number(item.quantity_incorrect) * 100) / (Number(item.quantity_correct_detail) + Number(item.quantity_incorrect_detail));
        },
        save() {
            if (this.$refs.form.validate()) {
                this.editedItem.detalles = this.detalles_orden_produccion;
                this.loading = true;
                this.disBoton = true;
                service
                    .ingresarDatos(this.url_ruta, this.editedItem)
                    .then((response) => {
                        this.loading = false;
                        this.disBoton = false;
                        if (response.data.response_flag == 1) {
                            this.dialog = false;
                            this.dialog_save = false;
                            this.consultarOrdenes();
                        }

                    })
                    .catch(() => {
                        this.loading = false;
                        console.log("Problema al consultar evaluaciones de maquinas");
                    });
            }
        },

        agregarDetalle() {
            if (this.pieza_seleccionada != null) {
                if (this.cantidad_seleccionada > 0) {
                    if (this.detalles_orden_produccion.findIndex(element => element.id == this.pieza_seleccionada.id) === -1) {
                        this.pieza_seleccionada.quantity = this.cantidad_seleccionada;
                        this.pieza_seleccionada.piece_id = this.pieza_seleccionada.id;
                        this.detalles_orden_produccion.push(this.pieza_seleccionada);
                        this.pieza_seleccionada = null;
                        this.cantidad_seleccionada = 0;
                        this.error_messages = [];
                        this.error_messages_cantidad = [];
                    } else {
                        this.error_messages = [];
                        this.error_messages.push("La pieza ya fue seleccionada a la orden de producción");
                    }
                } else {
                    this.error_messages_cantidad = [];
                    this.error_messages_cantidad.push("La cantidad de producción debe ser mayor a 0");
                }
            } else {
                this.error_messages = [];
                this.error_messages.push("Debes seleccionar una pieza");
            }
        },

        consultarDetalle(item) {
            this.editedItem = item;
            this.disBoton = true;
            this.step = 1;
            this.dialog_info = true;
            this.titulo = "Orden de Planeación No.: " + item.id
            this.detalles_orden_produccion = [];
            this.consultarDetalles();
            this.consultarOrdenesEvaluaciones();
        },

        inicializarFechas() {
            var date = new Date();
            var current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            this.editedItem.start_date = current_date;
            this.fecha_inicio = current_date;
            this.editedItem.expected_end_date = current_date;
            this.fecha_termino = current_date;
        },
        configurarPiezasTurnosTotales(response) {
            //Obtenemos los totales de piezas realizadas 
            let piezas_realizadas = 0;
            let porcentaje_piezas_realizadas = 0;
            let piezas_no_realizadas = 0;
            let piezas_totales = 0;
            let porcentaje_piezas_no_realizadas = 0;
            response.forEach(evaluacion_seleccionada => {
                piezas_realizadas = piezas_realizadas + Number(evaluacion_seleccionada.quantity_correct);
                piezas_no_realizadas = piezas_no_realizadas + Number(evaluacion_seleccionada.quantity_incorrect);
            });
            piezas_totales = piezas_realizadas + piezas_no_realizadas;

            porcentaje_piezas_realizadas = (Number(piezas_realizadas) * 100) / Number(piezas_totales);
            porcentaje_piezas_no_realizadas = (Number(piezas_no_realizadas) * 100) / Number(piezas_totales);

            console.log(porcentaje_piezas_no_realizadas, porcentaje_piezas_realizadas);
            this.chartDataPorcentajesPiezas.labels = ["Piezas Realizadas", "SCRAP"]
            this.chartDataPorcentajesPiezas.datasets[0].data = [];
            this.chartDataPorcentajesPiezas.datasets[0].data.push(porcentaje_piezas_realizadas);
            this.chartDataPorcentajesPiezas.datasets[0].data.push(porcentaje_piezas_no_realizadas);
        },
        configurarPiezasEmpleadosTotales(response) {
            let empleados_seleccionadas = new Array();
            console.log(empleados_seleccionadas);
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let empleado_index_seleccionado = empleados_seleccionadas.findIndex(element => element.employee_id == evaluacion_seleccionada.employee_id);
                //Si existe incrementamos la cantidad realizada
                if (empleado_index_seleccionado > -1) {
                    empleados_seleccionadas[empleado_index_seleccionado].cantidad_realizada = (Number(empleado_index_seleccionado[empleado_index_seleccionado].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                    empleados_seleccionadas[empleado_index_seleccionado].cantidad_no_realizada = (Number(empleado_index_seleccionado[empleado_index_seleccionado].cantidad_no_realizada) + Number(evaluacion_seleccionada.quantity_incorrect));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    evaluacion_seleccionada.cantidad_no_realizada = evaluacion_seleccionada.quantity_incorrect;
                    empleados_seleccionadas.push(evaluacion_seleccionada);
                }
            });
           
            this.chartDataPiezasEmpleados.labels = []
            this.chartDataPiezasEmpleados.datasets[0].data = []
            this.chartDataPiezasEmpleados.datasets[1].data = []

            empleados_seleccionadas.forEach(empleado_seleccionado => {
                this.chartDataPiezasEmpleados.labels.push(empleado_seleccionado.employee);
                this.chartDataPiezasEmpleados.datasets[0].data.push(empleado_seleccionado.cantidad_realizada);
                this.chartDataPiezasEmpleados.datasets[1].data.push(empleado_seleccionado.cantidad_no_realizada);
            });
        },
        configurarPiezasDiasTotales(response) {
            let dias_seleccionadas = new Array();
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let dia_seleccionado_index = dias_seleccionadas.findIndex(element => element.evaluation_date === evaluacion_seleccionada.evaluation_date);
                //Si existe incrementamos la cantidad realizada
                if (dia_seleccionado_index > -1) {
                    dias_seleccionadas[dia_seleccionado_index].cantidad_realizada = (Number(dias_seleccionadas[dia_seleccionado_index].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                    dias_seleccionadas[dia_seleccionado_index].cantidad_no_realizada = (Number(dias_seleccionadas[dia_seleccionado_index].cantidad_no_realizada) + Number(evaluacion_seleccionada.quantity_incorrect));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    evaluacion_seleccionada.cantidad_no_realizada = evaluacion_seleccionada.quantity_incorrect;
                    dias_seleccionadas.push(evaluacion_seleccionada);
                }
            });

            this.chartDataDiasPiezas.labels = []
            this.chartDataDiasPiezas.datasets[0].data = []
            this.chartDataDiasPiezas.datasets[1].data = []
            dias_seleccionadas.forEach(dia_seleccionado => {
                this.chartDataDiasPiezas.labels.push(dia_seleccionado.evaluation_date);
                this.chartDataDiasPiezas.datasets[0].data.push(dia_seleccionado.cantidad_realizada);
                this.chartDataDiasPiezas.datasets[1].data.push(dia_seleccionado.cantidad_no_realizada);
            });
        },
    },
}
</script>