<template>
    <div>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="64">
                    <v-toolbar flat>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                            Hoy
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu bottom right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>
                                        mdi-menu-down
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Día</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Semana</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Mes</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                    <v-calendar ref="calendar" v-model="focus" color="primary" :events="events"
                        :event-color="getEventColor" :type="type" @click:event="verDetalle" @click:more="viewDay"
                        @click:date="viewDay" @change="setCalendar">
                    </v-calendar>
                    <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement"
                        offset-x>
                        <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>
                                <v-btn icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                    <v-icon>mdi-heart</v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <span v-html="selectedEvent.details"></span>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text color="secondary" @click="selectedOpen = false">
                                    Cancel
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDetalle" width="1200" persistent>
            <v-card>
                <v-card-title>
                    <span class="text--h5">
                        DETALLE DE LA EVALUACIÓN
                    </span>
                </v-card-title>
                <v-card-text>
                    <div v-if="loader">
                        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                    </div>
                    <div v-else>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field v-model="evaluacionDetalle.maquina" label="Máquina" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-text-field v-model="evaluacionDetalle.metodologia" label="Metodología" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-text-field v-model="evaluacionDetalle.pieza" label="Pieza" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="8" md="8">
                                    <v-text-field v-model="evaluacionDetalle.cliente" label="Cliente" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-text-field v-model="evaluacionDetalle.turno" label="Turno" readonly>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <template>
                                    <v-data-table :headers="cabecera_detalle" :items="detalleEvaluacion"
                                        class="elevation-1">
                                        <template v-slot:[`item.accionesArticulo`]="{item}">
                                            <v-icon color="buttonEdit" small @click="abrirVentanaIncidencias(item)">
                                                sms_failed
                                            </v-icon>
                                        </template>
                                        <template v-slot:[`item.is_correct`]="{item}">
                                            <v-chip :color="evaluarSiHuboIncidencia(item)" dark>
                                            </v-chip>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-row>
                        </v-container>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer>
                    </v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDetalle = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_detail_image" max-width="800px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">
                        Evidencia de la incidencia
                    </span>
                </v-card-title>
                <v-card-text>
                    <template>
                        <div v-if="loaderImage">
                            <v-skeleton-loader class="mx-auto" type="image">
                            </v-skeleton-loader>
                        </div>
                        <div v-else>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" v-for="imagenes in detalle_actividad_imagenes"
                                    :key="imagenes.id">
                                    <v-img :src="imagenes.image" heigth="300" width="300" @click="openImage(imagenes)">
                                    </v-img>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer>
                    </v-spacer>
                    <v-btn text color="primary" @click="closeDialogDetailImage">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-overlay :value="overlayValue">
                <v-img heigth="800" width="800" :src="overlayImage" @click="closeImage">
                </v-img>
            </v-overlay>
        </v-dialog>
    </div>
</template>

<script>
import service from '@/core/Service.js';
export default {
    data: () => ({
        url_ruta: 'machine_evaluation',
        url_ruta_machine_evaluation_images: "machine_evaluation_images",
        evaluacion_maquinas: [],
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Mes',
            week: 'Semana',
            day: 'Día'
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        dialogDetalle: false,
        dialog_detail_image: false,
        evaluacionDetalle: {
            id: 0,
            maquina: "",
            metodologia: "",
            cliente: "",
            pieza: "",
            turno: ""
        },
        detalleEvaluacion: [],
        cabecera_detalle: [
            { text: 'Actividad', value: 'activity' },
            { text: 'Categoria Actividad', value: 'description' },
            { text: 'Porcentaje Mínimo', value: 'minimum_percentage' },
            { text: 'Porcentaje Máximo', value: 'maximum_percentage' },
            { text: 'Calificación', value: 'califacation' },
            { text: 'Incidencia', value: 'is_correct' },
            { text: 'Acciones', value: 'accionesArticulo', sortable: false }
        ],
        loader: false,
        loaderImage: false,
        overlayValue: false,
    }),

    mounted() {
        this.$refs.calendar.checkChange()
    },

    created() {
        this.consultarOrdenesProduccion();
    },

    methods: {
        consultarOrdenesProduccion() {
            service.consultarDatos(this.url_ruta).then((response) => {
                if (response.data.response_flag === 1) {
                    this.evaluacion_maquinas = response.data.response;
                    this.setCalendar();
                }
            }).catch((error) => {
                console.log("Problemas al consultar las ordenes de producción" + error);
            })
        },

        consultarDetalleEvaluacionMaquinas() {
            this.loader = true;
            service.buscarDatos(this.url_ruta, this.evaluacionDetalle.id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.loader = false;
                    let data = response.data.response;
                    data.forEach(element => {
                        if (element.activity_category_id == 2) {
                            element.minimum_percentage = "N/A"
                            element.maximum_percentage = "N/A"
                            element.califacation = (element.is_correct == 0) ? "NO" : "SI"
                        }
                    });
                    this.detalleEvaluacion = data;
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        consultarActividadesImagenes(id) {
            this.loaderImage = true;
            service.buscarDatos(this.url_ruta_machine_evaluation_images, id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.loaderImage = false;
                    this.detalle_actividad_imagenes = response.data.response;
                }
            })
                .catch((error) => {
                    console.log("Problema al consultar las imagenes de la incidencia" + error);
                })
        },

        viewDay({ date }) {
            this.focus = date
            this.type = 'day'
        },

        getEventColor(event) {
            return event.color
        },

        setToday() {
            this.focus = ''
        },

        prev() {
            this.$refs.calendar.prev()
        },

        next() {
            this.$refs.calendar.next()
        },

        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        setCalendar() {
            const events = [];

            this.evaluacion_maquinas.forEach(element => {
                events.push({
                    name: element.machine,
                    start: element.date_evaluation,
                    end: element.date_evaluation,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    detalle: {
                        id: element.id,
                        metodologia: element.document,
                        cliente: element.name_or_business_name,
                        maquina: element.machine,
                        pieza: element.piece,
                        turno: element.turn
                    }
                })
            });
            this.events = events;
            console.log(this.evaluacion_maquinas);
        },

        verDetalle({ event }) {
            this.evaluacionDetalle = Object.assign({}, event.detalle);
            this.consultarDetalleEvaluacionMaquinas();
            this.dialogDetalle = true;
        },

        evaluarSiHuboIncidencia(item) {
            if (item.is_correct == 0) {
                return "red"
            } else {
                return "green"
            }
        },

        abrirVentanaIncidencias(item) {
            this.consultarActividadesImagenes(item.id);
            this.dialog_detail_image = true;
        },

        openImage(item) {
            this.overlayImage = item.image;
            this.overlayValue = true;
        },

        closeImage() {
            this.overlayImage = null;
            this.overlayValue = false;
        },
        
        closeDialogDetailImage() {
            this.detalle_actividad_imagenes = [];
            this.dialog_detail_image = false;
        },
        
        updateRange({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)
            const days = (max.getTime() - min.getTime()) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0
                const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                const second = new Date(first.getTime() + secondTimestamp)

                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                })
            }

            this.events = events
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
    },
}
</script>