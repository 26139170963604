import axios from "axios";
import authentication from "@/core/Autenticacion";

const API_URL =
  "http://siet.backend.sdib.com.mx/public/api/";

class ICSSYS {
  //Autenticación
  login(params) {
    return axios.post(API_URL + "auth/login", params);
  }

  //#region Funciones Crud
  consultarDatos(url_ruta) {
    return axios.get(API_URL + url_ruta, authentication());
  }

  ingresarDatos(url_ruta,params) {
    return axios.post(API_URL + url_ruta, params, authentication());
  }

  modificarDatos(url_ruta,params,id) {
    return axios.put(API_URL + url_ruta+"/" + id, params, authentication());
  }

  eliminarDatos(url_ruta,params,id){
    let usuario_actual = JSON.parse(localStorage.getItem('usuario'));
    return axios.delete(API_URL + url_ruta+"/" + id, {
      headers: {
        Authorization: 'Bearer ' + usuario_actual.token
      },
      data: params,
    });
  }
  
  buscarDatos(url_ruta,id){
    return axios.get(API_URL + url_ruta+"/" + id, authentication());
  }
  //#endregion

  //# regionPerfiles
  consultarPerfiles() {
    return axios.get(API_URL + "profile", authentication());
  }

  ingresarPerfil(params) {
    return axios.post(API_URL + "profile", params, authentication());
  }

  modificarPerfil(params, id) {
    return axios.put(API_URL + "profile/" + id, params, authentication());
  }

  eliminarPerfil(params, id) {
    return axios.delete(API_URL + "profile/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Categoria de Maquina
  consultarCategoriaMaquina() {
    return axios.get(API_URL + "machine_category", authentication());
  }

  ingresarCategoriaMaquina(params) {
    return axios.post(API_URL + "machine_category", params, authentication());
  }

  modificarCategoriaMaquina(params, id) {
    return axios.put(
      API_URL + "machine_category/" + id,
      params,
      authentication()
    );
  }

  eliminarCategoriaMaquina(params, id) {
    return axios.delete(API_URL + "machine_category/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Maquinas
  consultarMaquinas() {
    return axios.get(API_URL + "machine", authentication());
  }

  ingresarMaquina(params) {
    return axios.post(API_URL + "machine", params, authentication());
  }

  modificarMaquina(params, id) {
    return axios.put(API_URL + "machine/" + id, params, authentication());
  }

  eliminarMaquina(params, id) {
    return axios.delete(API_URL + "machine/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Documentos
  consultarDocumentos() {
    return axios.get(API_URL + "document", authentication());
  }

  ingresarDocumento(params) {
    return axios.post(API_URL + "document", params, authentication());
  }

  modificarDocumento(params, id) {
    return axios.put(API_URL + "document/" + id, params, authentication());
  }

  eliminarDocumento(params, id) {
    return axios.delete(API_URL + "document/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Actividades
  consultarActividades() {
    return axios.get(API_URL + "activity", authentication());
  }

  ingresarActividad(params) {
    return axios.post(API_URL + "activity", params, authentication());
  }

  modificarActividad(params, id) {
    return axios.put(API_URL + "activity/" + id, params, authentication());
  }

  eliminarActividad(params, id) {
    return axios.delete(API_URL + "activity/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Departamentos
  consultarDepartamentos() {
    return axios.get(API_URL + "departament", authentication());
  }

  ingresarDepartamento(params) {
    return axios.post(API_URL + "departament", params, authentication());
  }

  modificarDepartamento(params, id) {
    return axios.put(API_URL + "departament/" + id, params, authentication());
  }

  eliminarDepartamento(params, id) {
    return axios.delete(API_URL + "departament/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Puestos
  consultarPuestos() {
    return axios.get(API_URL + "position", authentication());
  }

  ingresarPuesto(params) {
    return axios.post(API_URL + "position", params, authentication());
  }

  modificarPuesto(params, id) {
    return axios.put(API_URL + "position/" + id, params, authentication());
  }

  eliminarPuesto(params, id) {
    return axios.delete(API_URL + "position/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Unidades de Media
  consultarUnidadMedia() {
    return axios.get(API_URL + "unit", authentication());
  }

  ingresarUnidadMedia(params) {
    return axios.post(API_URL + "unit", params, authentication());
  }

  modificarUnidadMedia(params, id) {
    return axios.put(API_URL + "unit/" + id, params, authentication());
  }

  eliminarUnidadMedia(params, id) {
    return axios.delete(API_URL + "unit/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Piezas
  consultarPiezas() {
    return axios.get(API_URL + "piece", authentication());
  }

  ingresarPieza(params) {
    return axios.post(API_URL + "piece", params, authentication());
  }

  modificarPieza(params, id) {
    return axios.put(API_URL + "piece/" + id, params, authentication());
  }

  eliminarPieza(params, id) {
    return axios.delete(API_URL + "piece/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Categoria de Pieza
  consultarCategoriaPieza() {
    return axios.get(API_URL + "piece_category", authentication());
  }

  ingresarCategoriaPieza(params) {
    return axios.post(API_URL + "piece_category", params, authentication());
  }

  modificarCategoriaPieza(params, id) {
    return axios.put(
      API_URL + "piece_category/" + id,
      params,
      authentication()
    );
  }

  eliminarCategoriaPieza(params, id) {
    return axios.delete(API_URL + "piece_category/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Maquina Actividades
  ingresarDocumentoActividad(params) {
    return axios.post(API_URL + "document_activity", params, authentication());
  }

  consultarActividadesAsigandas(id) {
    return axios.get(API_URL + "document_activity/" + id, authentication());
  }
  //#endregion

  //#region Maquina Documento
  ingresarMaquinaDocumento(params) {
    return axios.post(API_URL + "machine_document", params, authentication());
  }

  consultarDocumentosAsignados(id) {
    return axios.get(API_URL + "machine_document/" + id, authentication());
  }
  //#endregion

  //#region Turnos
  consultarTurnos() {
    return axios.get(API_URL + "turn", authentication());
  }

  ingresarTurno(params) {
    return axios.post(API_URL + "turn", params, authentication());
  }

  modificarTurno(params, id) {
    return axios.put(API_URL + "turn/" + id, params, authentication());
  }

  eliminarTurno(params, id) {
    return axios.delete(API_URL + "turn/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Clientes
  consultarClientes() {
    return axios.get(API_URL + "client", authentication());
  }

  ingresarCliente(params) {
    return axios.post(API_URL + "client", params, authentication());
  }

  modificarCliente(params, id) {
    return axios.put(API_URL + "client/" + id, params, authentication());
  }

  eliminarCliente(params, id) {
    return axios.delete(API_URL + "client/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Tipos de Incidencias
  consultarTipoIncidencias() {
    return axios.get(API_URL + "incidence_type", authentication());
  }

  ingresarTipoIncidencia(params) {
    return axios.post(API_URL + "incidence_type", params, authentication());
  }

  modificarTipoIncidencia(params, id) {
    return axios.put(
      API_URL + "incidence_type/" + id,
      params,
      authentication()
    );
  }

  eliminarTipoIncidencia(params, id) {
    return axios.delete(API_URL + "incidence_type/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Empleados
  consultarEmpleados() {
    return axios.get(API_URL + "employee", authentication());
  }

  ingresarEmpleado(params) {
    return axios.post(API_URL + "employee", params, authentication());
  }

  modificarEmpleado(params, id) {
    return axios.put(API_URL + "employee/" + id, params, authentication());
  }

  eliminarEmpleado(params, id) {
    return axios.delete(API_URL + "employee/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //#endregion

  //#region Compañía
  consultarCompanias() {
    return axios.get(API_URL + "company", authentication());
  }

  ingresarCompanias(params) {
    return axios.post(API_URL + "company", params, authentication());
  }

  modificarCompanias(params, id) {
    return axios.put(API_URL + "company/" + id, params, authentication());
  }

  eliminarCompania(params, id) {
    return axios.delete(API_URL + "company/" + id, {
      headers: authentication(),
      data: params,
    });
  }
  //endregion

  consultarTiposSangre() {
    return axios.get(API_URL + "blood_type", authentication());
  }

  consultarTiposEmpleado() {
    return axios.get(API_URL + "employee_type", authentication());
  }

  consultarPosiciones() {
    return axios.get(API_URL + "position", authentication());
  }

  consultarMetodosPago() {
    return axios.get(API_URL + "payment_type", authentication());
  }

  consultarEstados() {
    return axios.get(API_URL + "state", authentication());
  }

  consultarMunicipios() {
    return axios.get(API_URL + "city", authentication());
  }

  consultarEvaluacionesMaquinas() {
    return axios.get(API_URL + "machine_evaluation", authentication());
  }

  generarEvaluacioneMaquina(params) {
    return axios.post(API_URL + "machine_evaluation", params, authentication());
  }
}

export default new ICSSYS();
