import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/InicioSesion.vue'
import Inicio from '../views/principal/Inicio.vue'
import Cliente from '../views/catalogos/Clientes.vue'
import Pieza from '../views/catalogos/Piezas.vue'
import Maquina from '../views/catalogos/Maquinas.vue'
import Actividad from '../views/catalogos/Actividades.vue'
import Perfil from '../views/catalogos/Perfiles.vue'
import Usuario from '../views/catalogos/Usuarios.vue'
import Documento from '../views/catalogos/Documentos.vue'
import CategoriaMaquina from '../views/catalogos/MaquinaCategoria.vue'
import Puesto from '../views/catalogos/Puestos.vue'
import Departamento from '../views/catalogos/Departamentos.vue'
import UnidadMedida from '../views/catalogos/UnidadMedida.vue'
import PiezaCategoria from '../views/catalogos/PiezaCategoria.vue'
import Turno from '../views/catalogos/Turnos.vue'
import InicidenciaTipo from '../views/catalogos/InicidenciaTipo.vue'
import EvaluacionMaquina from '../views/procesos/EvaluacionMaquina.vue'
import Empleados from '../views/catalogos/Empleados.vue'
import Companias from '../views/catalogos/Companias.vue'
import AreaTrabajo from '../views/catalogos/AreaTrabajo.vue'
import LineaProduccion from '../views/catalogos/LineaProduccion.vue'
import OrdenesProduccion from '../views/procesos/Ordenes Produccion/OrdenesProduccion.vue'
import EvaluacionOrdenesProduccion from '../views/procesos/Ordenes Produccion/OrdenesProduccionEvaluacion.vue'
import OrdenesProduccionInformacion from '../views/procesos/Ordenes Produccion/OrdenesProduccionInformacion.vue'
import CompaniaSeleccion from '../views/principal/CompaniaSeleccion.vue'
import Procesos from '../views/catalogos/Procesos.vue'
import Configuracion from '../views/principal/Configuracion.vue'
import Calendario from '../views/catalogos/Calendario.vue'
import ControlPanel from '../views/principal/PanelControl.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path:'/CompaniaSeleccion',
    name: 'CompaniaSeleccion',
    component: CompaniaSeleccion
  },
  {
    path:'/panel',
    name: 'PanelControl',
    component: ControlPanel
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: Inicio,
    children:
    [
      {
        path: '/cliente',
        name: 'Cliente',
        component: Cliente
      },
      {
        path: '/pieza',
        name: 'Pieza',
        component: Pieza
      },
      {
        path: '/maquina',
        name: 'Maquina',
        component: Maquina
      },
      {
        path: '/indicador',
        name: 'Indicador',
        component: Actividad
      },
      {
        path: '/perfil',
        name: 'Perfil',
        component: Perfil
      },
      {
        path: '/usuario',
        name: 'Usuario',
        component: Usuario
      },
      {
        path: '/documento',
        name: 'Documento',
        component: Documento
      },
      {
        path: '/maquina_categoria',
        name: 'Categoria Maquina',
        component: CategoriaMaquina
      },
      {
        path: '/puesto',
        name: 'Puesto',
        component: Puesto
      },
      {
        path: '/departamento',
        name: 'Departamento',
        component: Departamento
      },
      {
        path: '/unidad_medida',
        name: 'Unidad de Media',
        component: UnidadMedida
      },
      {
        path: '/categoria_pieza',
        name: 'Categoria de Pieza',
        component: PiezaCategoria
      },
      {
        path: '/evaluacion_maquina',
        name: 'Evaluacion Maquina',
        component: EvaluacionMaquina
      },
      {
        path: '/turno',
        name: 'Turno',
        component: Turno
      },
      {
        path: '/tipo_incidencia',
        name: 'Tipos Incidencias',
        component: InicidenciaTipo}
        ,{
        path: '/empleados',
        name: 'Empleados',
        component: Empleados
      },
      {
        path: '/companias',
        name: 'Companias',
        component: Companias
      },
      {
        path: '/area_trabajo',
        name: 'Area de Trabjo',
        component: AreaTrabajo
      },
      {
        path: '/linea_produccion',
        name: 'Linea de Producción',
        component: LineaProduccion
      },
      {
        path: '/ordenes_produccion',
        name: 'Ordenes de Producción',
        component: OrdenesProduccion
      },
      {
        path:'/evaluacion_orden_produccion',
        name: 'Evaluación de Ordenes de Producción',
        component: EvaluacionOrdenesProduccion
      },
      {
        path:'/informacion_orden_produccion',
        name: 'Ordenes de Producción',
        component: OrdenesProduccionInformacion
      },
      {
        path: '/procesos',
        name: 'Procesos',
        component: Procesos
      },
      {
        path:'/Configuracion',
        name: 'Configuracion',
        component: Configuracion
      },
      { path: '/Calendario', name: 'Calendario', component: Calendario }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
