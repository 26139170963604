export default function authHeader() {
    let usuario_actual = JSON.parse(localStorage.getItem('usuario'));
    let config = {
       
        headers: {
            Authorization: 'Bearer ' + usuario_actual.token
        }
    }

    return config;
}
