<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Metodologias de Calidad</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref = "form"  lazy-validation>
                    <v-dialog v-model="dialog" width="400px" max-width="600px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                <v-icon left>
                                    mdi-plus
                                </v-icon>
                                Nuevo
                            </v-btn>
                        </template>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.document" @input="editedItem.document = editedItem.document.toUpperCase()" label="Metodología" :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea v-model="editedItem.observations" @input="editedItem.observations = editedItem.observations.toUpperCase()" label="Observaciones" :rules=rules_validar>
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                    Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    </v-form>
                    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title class="text-h5">¿Estas seguro de eliminar?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon color="closeSesion" small class="mr-2" @click="deleteDetail(item)">
                    mdi-delete
                </v-icon>
                <v-icon color="buttonThird" small @click="addIndicator(item)">
                    mdi-clipboard-list-outline
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarDocumentos">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog_indicadores" width="900px" max-width="900px" persistent>
            <v-card :loading="loader_form">
                <template slot="progress">
                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                </template>
                <v-card-title>
                    <span class="text-h6">Indicadores Asignados</span>
                </v-card-title>
                <div v-if="loader_detail">
                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
                <div v-else>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10" md="10">
                                <v-autocomplete :items="indicadores" item-text="activity_full_code" item-value="id"
                                    v-model="indicador_seleccionado" :error-messages="error_messages" return-object
                                    label="Indicador">
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="2" md="2">
                                <v-btn class="mx-2" fab dark color="primary" @click="agregarDetalle">
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table :headers="indicadores_encabezado" :items="indicadores_detalles"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon small @click="deleteDetail(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.orden="{ item }">
                                <v-icon small class="mr-2" @click="bajarElemento(item)">
                                    mdi-arrow-collapse-down
                                </v-icon>
                                <v-icon small @click="subirElemento(item)">
                                    mdi-arrow-collapse-up
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog_indicadores = false" :disabled="disBoton">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="saveActivities" :disabled="disBoton">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <template>
            <v-snackbar
                v-model = "alert"
                :timeout="tiempo_muestra"
                :value="true"
                :color = "color_alerta"
                absolute
                right
                rounded="pill"
            >
            <v-icon
                class="mr-3"
            >
                {{icono_alerta}}
            </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>
<script>

import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Documentos'
    },
    data: () => ({
        tiempo_muestra:1500,
        url_ruta:"document",
        alert: false,
        texto_alerta:"",
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        dialogDelete: false,
        loading: false,
        disBoton: false,
        dialog_indicadores: false,
        search: "",
        loader_form:false,
        loader: true,
        loader_detail: true,
        encabezados: [
            {
                text: 'Documento',
                value: 'document',
            },
            {
                text: 'Observaciones',
                value: 'observations',
            },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        indicadores_encabezado: [
            { text: 'Ordenamiento', value: 'orden', sortable: false },
            { text: 'Clave', value: 'code', sortable: false },
            { text: 'Indicador', value: 'activity', sortable: false },
            { text: 'Categoria', value: 'description', sortable: false },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        indicadores_detalles: [
        ],
        detalles: [],
        indicadores_detalles_temp: [],
        indicadores: [],
        editedIndex: -1,
        error_messages: [],
        indicador_seleccionado: null,
        editedItem: {
            id: 0,
            document: '',
            observations: '',
        },
        defaultItem: {
            id: 0,
            document: '',
            observations: '',
        },
        rules_validar: [(v) => !!v || "Campo requerido"]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva Metodología' : 'Modificar Metodología'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.consultarDocumentos();
        this.consultarActividades();
    },

    methods: {
        consultarDocumentos() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar los documentos");
                });
        },

        consultarActividades() {
            this.loader = true;
            service
                .consultarActividades()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.indicadores = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar actividades");
                });
        },

        consultarActividadesAsigandas() {
            this.loader_detail = true;
            service
                .consultarActividadesAsigandas(this.editedItem.id)
                .then((response) => {
                    this.loader_detail = false;
                    if (response.data.response_flag == 1) {
                        this.indicadores_detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader_detail = false;
                    console.log("Problema al consultar actividades");
                });
        },

        editItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        addIndicator(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog_indicadores = true;
            this.consultarActividadesAsigandas();
        },

        agregarDetalle() {
            if (this.indicador_seleccionado != null) {
                if (this.indicadores_detalles.findIndex(element => element.id == this.indicador_seleccionado.id) === -1) {
                    this.indicadores_detalles.push(this.indicador_seleccionado);
                    this.indicador_seleccionado = null;
                    this.error_messages = [];
                } else {
                    this.error_messages = [];
                    this.error_messages.push("El indicador seleccionado ya fue relacionado al documento");
                }
            } else {
                this.error_messages = [];
                this.error_messages.push("Debes seleccionar uno de los indicadores");
            }
        },

        subirElemento(element_seleccionado) {
            let indice_seleccionado = this.indicadores_detalles.findIndex(element => element.id == element_seleccionado.id);
            if (indice_seleccionado != 0) {
                let indicador_siguiente = this.indicadores_detalles[indice_seleccionado - 1];
                this.indicadores_detalles[indice_seleccionado] = indicador_siguiente;
                this.indicadores_detalles[indice_seleccionado - 1] = element_seleccionado;
                let indicadores_detalles_temp = this.indicadores_detalles;
                this.indicadores_detalles = [];
                indicadores_detalles_temp.forEach(element => {
                    this.indicadores_detalles.push(element);
                });
            }
        },
        
        bajarElemento(element_seleccionado) {
            let medida_arreglo = this.indicadores_detalles.length - 1;
            let indice_seleccionado = this.indicadores_detalles.findIndex(element => element.id == element_seleccionado.id);
            if (indice_seleccionado != medida_arreglo) {
                let indicador_anterior = this.indicadores_detalles[indice_seleccionado + 1];
                this.indicadores_detalles[indice_seleccionado] = indicador_anterior;
                this.indicadores_detalles[indice_seleccionado + 1] = element_seleccionado;
                let indicadores_detalles_temp = this.indicadores_detalles;
                this.indicadores_detalles = [];
                indicadores_detalles_temp.forEach(element => {
                    this.indicadores_detalles.push(element);
                });
            }
        },

        deleteItemConfirm() {
            this.loading = true;
            this.disBoton = true;
            service
                .eliminarDocumento(this.editedItem, this.editedItem.id)
                .then((response) => {
                    if(response.data.response_flag === 1){
                        this.consultarDocumentos();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Se ha eliminado con éxito el documento"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if(response.data.response_flag !== 1 || response.data === null){
                        this.consultarDocumentos();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Ocurrió un error al eliminar el documento"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar los documentos");
                });
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        saveActivities() {
            this.loader_form = true;
            this.disBoton = true;
            this.editedItem.detalles = this.indicadores_detalles;
            service
                .ingresarDocumentoActividad(this.editedItem)
                .then((response) => {
                    if(response.data.response_flag === 1){
                    this.loader_form = false;
                    this.disBoton = false;
                    this.dialog_indicadores = false;
                    this.texto_alerta= "Se han guardado con éxito las actividades"
                    this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                    this.color_alerta = "success";
                    this.alert = true;
                    }
                    else if(response.data.response_flag !== 1 || response.data === null){
                    this.loader_form = false;
                    this.disBoton = false;
                    this.dialog_indicadores = false;
                    this.texto_alerta= "Ocurrió un error al guardar las actividades"
                    this.icono_alerta = "mdi-close-circle";
                    this.color_alerta = "red darken-2";
                    this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar los documentos");
                });
        },

        deleteDetail(item){
            this.indicadores_detalles.splice(item, 1)
        },

        save() {
            if(this.$refs.form.validate()){
                this.disBoton = true;
                this.loading = true;
                if (this.editedIndex > -1) {
                service
                    .modificarDocumento(this.editedItem, this.editedItem.id)
                    .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarDocumentos();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha modificado con éxito el documento"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarDocumentos();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar el documento"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                    .catch(() => {
                        console.log("Problema al consultar los documentos");
                    });
                } else {
                service
                    .ingresarDocumento(this.editedItem)
                    .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarDocumentos();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha guardado con éxito el documento"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarDocumentos();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar el documento"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                    .catch(() => {
                        console.log("Problema al consultar los documentos");
                    });
            }
            }
        },
    },
}
</script>