<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Piezas</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref="form" lazy-validation>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card :loading="loading">
                                <template slot="progress">
                                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                                </template>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-text-field v-model="editedItem.code" @input="editedItem.code = editedItem.code.toUpperCase()" label="Clave"
                                                    :rules=rules_validar></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="9" md="9">
                                                <v-text-field v-model="editedItem.piece" @input="editedItem.piece = editedItem.piece.toUpperCase()" label="Pieza"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-autocomplete :items="categorias" item-text="piece_category"
                                                    item-value="id" v-model="editedItem.piece_category_id"
                                                    label="Categoria" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-autocomplete :items="unidades" item-text="unit" item-value="id"
                                                    v-model="editedItem.unit_id" label="Unidad de Medida"
                                                    :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea v-model="editedItem.observations" @input="editedItem.observations = editedItem.observations.toUpperCase()" label="Observaciones">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                    <v-card :loading="loading">
                        <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                        <v-card-title class="text-h5">¿Estas seguro de eliminar ésta pieza?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="closeSesion" small class="mr-2" @click="deleteItem(item)">
                mdi-delete
            </v-icon>
            <v-icon color="buttonThird" small @click="addProcess(item)">
                mdi-wrench
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="consultarPiezas">
                Consultar
            </v-btn>
        </template>
    </v-data-table>
        <v-dialog v-model="dialogProcess" width="600px" max-width="600px" persistent>
            <v-card :loading="loader_form">
                <template slot="progress">
                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                </template>
                <v-card-title>
                    <span class="text-h6">Procesos Asignados para: {{pieza_nombre}}</span>
                </v-card-title>
                <div v-if="loader_detail">
                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
                <div v-else>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10" md="10">
                                <v-autocomplete :items="procesos" item-text="process" item-value="id"
                                    v-model="proceso_seleccionado" :error-messages="error_messages" return-object
                                    label="Proceso">
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="2" md="2">
                                <v-btn class="mx-2" fab dark color="primary" @click="agregarDetalle">
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table :headers="procesos_encabezado" :items="procesos_detalles"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon color="closeSesion" small @click="deleteDetail(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.orden="{ item }">
                                <v-icon color="buttonThird" small class="mr-2" @click="bajarElemento(item)">
                                    mdi-arrow-collapse-down
                                </v-icon>
                                <v-icon color="buttonThird" small @click="subirElemento(item)">
                                    mdi-arrow-collapse-up
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogProcess = false" :disabled="disBoton">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="saveProcesses" :disabled="disBoton">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <template>
            <v-snackbar
                v-model = "alert"
                :timeout="tiempo_muestra"
                :value="true"
                :color = "color_alerta"
                absolute
                right
                rounded="pill"
            >
            <v-icon
                class="mr-3"
            >
                {{icono_alerta}}
            </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Piezas'
    },
    data: () => ({
        url_ruta_procesos: "process",
        url_ruta_piezas_procesos: "piece_process",
        tiempo_muestra:1500,
        url_ruta:"piece",
        pieza_nombre: "",
        alert: false,
        texto_alerta:"",
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        loader: true,
        loader_form: false,
        loader_detail: true,
        disBoton: false,
        loading: false,
        
        error_messages: [],
        dialogDelete: false,
        dialogProcess: false,
        search: "",
        encabezados: [
            {
                text: 'Clave',
                value: 'code',
            },
            { text: 'Pieza', value: 'piece' },
            { text: 'Unidad de Medida', value: 'unit' },
            { text: 'Categoria', value: 'piece_category' },
            { text: 'Observaciones', value: 'observations' },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        procesos_encabezado: [
            { text: 'Ordenamiento', value: 'orden', sortable: false },
            { text: 'Proceso', value: 'process', sortable: false },
            { text: 'Detalle', value: 'observations', sortable: false },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        unidades: [],
        categorias: [],
        procesos: [],
        detalles: [],
        procesos_detalles: [],
        proceso_seleccionado: null,
        editedIndex: -1,
        editedItem: {
            code: '',
            piece: '',
            observations: '',
            unit_id: '',
            piece_category_id: 0
        },
        defaultItem: {
            code: '',
            piece: '',
            observations: '',
            unit_id: '',
            piece_category_id: 0
        },
        rules_validar: [(v) => !!v || "Campo requerido"]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva Pieza' : 'Modificar Pieza'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.consultarPiezas();
        this.consultarUnidadMedia();
        this.consultarCategoriaPieza();
        this.consultarProcesos();
    },

    methods: {
        consultarUnidadMedia() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.unidades = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las unidades de medida");
                });
        },

        consultarProcesos(){
            this.loader = true;
            service.consultarDatos(this.url_ruta_procesos).then((response) =>{
                this.loader = false;
                if(response.data.response_flag === 1){
                    this.procesos = response.data.response;
                }
            })
            .catch((error) => {
                console.log("Problema al consultar los procesos" + error);
            });
        },

        consultarProcesosAsignados(){
            this.loader_detail = true;
            service.buscarDatos(this.url_ruta_piezas_procesos, this.editedItem.id).then((response) =>{
                this.loader_detail = false;
                if(response.data.response_flag === 1){
                    this.procesos_detalles = response.data.response;
                }
            })
            .catch((error) =>{
                this.loader_detail = false;
                console.log("Problema al consultar los detalles" + error);
            })
        },

        consultarPiezas() {
            this.loader = true;
            service
                .consultarPiezas()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las piezas");
                });
        },

        consultarCategoriaPieza() {
            this.loader = true;
            service
                .consultarCategoriaPieza()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.categorias = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las categorias");
                });
        },

        editItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        addProcess(item){
            this.editedIndex = this.detalles.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.pieza_nombre = this.editedItem.piece;
            this.dialogProcess = true;
            this.consultarProcesosAsignados();
        },

        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteDetail(item){
            this.procesos_detalles.splice(item, 1);
        },

        deleteItemConfirm() {
            this.loading = true;
            this.disBoton = true;
            service
                .eliminarDatos(this.url_ruta, this.editedItem , this.editedItem.id)
                .then((response) => {
                    if(response.data.response_flag === 1){
                        this.consultarPiezas();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Se ha eliminado con éxito la pieza"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if(response.data.response_flag !== 1 || response.data === null){
                        this.consultarPiezas();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Ocurrió un error al eliminar la pieza"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar las piezas");
                });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        agregarDetalle(){
            if (this.proceso_seleccionado != null) {
                if (this.procesos_detalles.findIndex(element => element.id == this.proceso_seleccionado.id) === -1) {
                    this.procesos_detalles.push(this.proceso_seleccionado);
                    this.proceso_seleccionado = null;
                    this.error_messages = [];
                } 
                else {
                    this.error_messages = [];
                    this.error_messages.push("El proceso seleccionado ya fue relacionado a la pieza");
                }
            } 
            else {
                this.error_messages = [];
                this.error_messages.push("Debes seleccionar uno de los procesos");
            }
        },

        subirElemento(element_seleccionado) {
            let indice_seleccionado = this.procesos_detalles.findIndex(element => element.id == element_seleccionado.id);
            if (indice_seleccionado != 0) {
                let proceso_siguiente = this.procesos_detalles[indice_seleccionado - 1];
                this.procesos_detalles[indice_seleccionado] = proceso_siguiente;
                this.procesos_detalles[indice_seleccionado - 1] = element_seleccionado;
                let procesos_detalles_temp = this.procesos_detalles;
                this.procesos_detalles = [];
                procesos_detalles_temp.forEach(element => {
                    this.procesos_detalles.push(element);
                });
            }
            console.log(this.procesos_detalles);
        },

        bajarElemento(element_seleccionado) {
            let medida_arreglo = this.procesos.length - 1;
            let indice_seleccionado = this.procesos_detalles.findIndex(element => element.id == element_seleccionado.id);
            if (indice_seleccionado != medida_arreglo) {
                let indicador_anterior = this.procesos_detalles[indice_seleccionado + 1];
                this.procesos_detalles[indice_seleccionado] = indicador_anterior;
                this.procesos_detalles[indice_seleccionado + 1] = element_seleccionado;
                let procesos_detalles_temp = this.procesos_detalles;
                this.procesos_detalles = [];
                procesos_detalles_temp.forEach(element => {
                    this.procesos_detalles.push(element);
                });
            }
        },

        saveProcesses(){
            this.loader_form = true;
            this.disBoton = true;
            this.editedItem.detalles = this.procesos_detalles;
            service.ingresarDatos(this.url_ruta_piezas_procesos, this.editedItem).then((response) =>{
                if(response.data.response_flag === 1){
                    this.loader_form = false;
                    this.disBoton = false;
                    this.dialogProcess = false;
                    this.texto_alerta= "Se han guardado con éxito los procesos";
                    this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                    this.color_alerta = "success";
                    this.alert = true;
                }
                else if(response.data.response_flag !== 1 || response.data === null){
                    this.loader_form = false;
                    this.disBoton = false;
                    this.dialogProcess = false;
                    this.texto_alerta= "Ocurrió un error al guardar los procesos";
                    this.icono_alerta = "mdi-close-circle";
                    this.color_alerta = "red darken-2";
                    this.alert = true;
                }
            })
            .catch((error) =>{
                console.log("Problemas al insertar el proceso" + error);
            })
        },

        save() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.disBoton = true;
                if (this.editedIndex > -1) {
                 service
                .modificarDatos(this.url_ruta, this.editedItem,this.editedItem.id)
                .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarPiezas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha modificado con éxito la pieza";
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarPiezas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar la pieza"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                .catch(() => {
                    console.log("Problema al consultar las piezas");
                });
                } else {
                    service
                .ingresarDatos(this.url_ruta,this.editedItem)
                .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarPiezas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha guardado con éxito la pieza"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarPiezas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al guardar la pieza"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                .catch(() => {
                    console.log("Problema al consultar las maquinas");
                });
                }
            }
        },
    },
}
</script>