<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Lineas de Producción</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref = "form" lazy-validation>
                    <v-dialog v-model="dialog" width="400px" max-width="600px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                <v-icon left>
                                    mdi-plus
                                </v-icon>
                                Nuevo
                            </v-btn>
                        </template>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.production_line" @input="editedItem.production_line = editedItem.production_line.toUpperCase()" label="Linea de Producción" :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.observations" @input="editedItem.observations = editedItem.observations.toUpperCase()" label="Observaciones">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                    Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    </v-form>
                    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title class="text-h5">¿Estas seguro de eliminar?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon color="closeSesion" small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarAreas">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <template>
            <v-snackbar
                v-model = "alert"
                :timeout="tiempo_muestra"
                :value="true"
                :color = "color_alerta"
                absolute
                right
                rounded="pill"
            >
            <v-icon
                class="mr-3"
            >
                {{icono_alerta}}
            </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Lineas de Producción'
    },
    data: () => ({
        tiempo_muestra:1500,
        url_ruta:'production_line',
        alert: false,
        texto_alerta:"",
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        dialogDelete: false,
        disBoton: false,
        loading: false,
        search: "",
        loader: true,
        encabezados: [
            {
                text: 'Linea de Producción',
                value: 'production_line',
            },
            {
                text: 'Observaciones',
                value: 'observations',
            },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        detalles: [],
        editedIndex: -1,
        editedItem: {
            id: 0,
            production_line: '',
            observations: '',
        },
        defaultItem: {
            id: 0,
            production_line: '',
            observations: '',
        },
        rules_validar: [(v) => !!v || "Campo requerido"]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva Linea de Producción' : 'Modificar Linea de Producción'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.consultarAreas()
    },

    methods: {
        consultarAreas() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las linea de produccion");
                });
        },

        editItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.loading = true;
            this.disBoton = true;
            service
                .eliminarDatos(this.url_ruta, this.editedItem , this.editedItem.id)
                .then((response) => {
                    if(response.data.response_flag === 1){
                        this.consultarAreas();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Se ha eliminado con éxito la linea de producción"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if(response.data.response_flag !== 1 || response.data === null){
                        this.consultarAreas();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Ocurrió un error al eliminar la linea de producción"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar las lineas de producción");
                });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.$refs.form.resetValidation();
        },

        save() {
            if(this.$refs.form.validate()){
                this.loading = true;
                this.disBoton = true;
                if (this.editedIndex > -1) {
                 service
                .modificarDatos(this.url_ruta, this.editedItem,this.editedItem.id)
                .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarAreas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha modificado con éxito la linea de producción"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarAreas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar la linea de producción"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                .catch(() => {
                    console.log("Problema al consultar las lineas de producción");
                });
                } else {
                    service
                .ingresarDatos(this.url_ruta,this.editedItem)
                .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarAreas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha modificado con éxito la linea de producción"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarAreas();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al guardar la linea de producción"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                .catch(() => {
                    console.log("Problema al consultar las lineas de producción");
                });
                }
            }
        },
    },
}
</script>