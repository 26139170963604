<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search"
            group-by="Orden de Producción">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Ordenes de Planeación Asignadas</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:item.progress="{item}">
                <v-progress-linear :color=obtenerColorPorcentaje(item) height="10" :value=obtenerPorcenctaje(item)
                    striped>
                    <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                </v-progress-linear>
            </template>
            <template v-slot:item.scrap="{item}">
                <v-progress-linear :color=obtenerColorPorcentajeScrap(item) height="10"
                    :value=obtenerPorcenctajeScrap(item) striped>
                    <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                </v-progress-linear>
            </template>
            <template v-slot:item.quantity_incorrect="{item}">
                <v-chip color="closeSesion" class="white--text">
                    {{item.quantity_incorrect}}
                </v-chip>
            </template>
            <template v-slot:item.actions="{item}">
                <v-icon small color="#47ED56" class="mr-2" @click="ingresarCantidadProduccion(item)">
                    mdi-account-check
                </v-icon>
                <v-icon small color="buttonFourth" @click="showFinishProcessWithoutCut(item)">
                    mdi-skip-next-circle
                </v-icon>
            </template>
            <template v-slot:item.proyection="{ item }">
                <v-chip :color="getColor(item)" dark>
                    {{ item.proyection }}
                </v-chip>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarCategoriaMaquina">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <template>
            <v-snackbar v-model="alert" :timeout="tiempo_muestra" :value="true" :color="color_alerta" absolute right
                rounded="pill">
                <v-icon class="mr-3">
                    {{icono_alerta}}
                </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>

        <v-dialog v-model="dialog" width="800px" persistent>
            <v-card :loading="loading">
                <v-form ref="form" lazy-validation>
                    <template slot="progress">
                        <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                    </template>
                    <v-card-title>
                        <span class="text-h5">{{formTitle}}</span>
                        <v-spacer>
                        </v-spacer>
                        <span class="text-h5">{{titleProcess}}</span>
                        <v-spacer>
                        </v-spacer>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.piece" label="Pieza" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.unit" label="Unidad de Medida" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.piece_category" label="Categoria de Pieza"
                                        readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.quantity" label="Cantidad Producción" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="editedItem.quantity_correct" label="Cantidad Producida"
                                        readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="cantidad_realizada" label="Cantidad Realizada" type="number"
                                        :error-messages="error_messages" :rules=rules_validar>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="cantidad_no_realizada" label="SCRAP" type="number"
                                        @input="mostrarElementos" :rules="rules_scrap">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" :style="disComponente">
                                    <v-text-field v-model="observaciones_proceso"
                                        @input="observaciones_proceso = observaciones_proceso.toUpperCase()"
                                        label="Observaciones">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="showFinishProcess">
                            Finalizar Proceso
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            Generar corte
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogFinish" max-width="800px" persistent>
            <v-card :loading="loading">
                <template slot="progress">
                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                </template>
                <v-card-title class="text-h5">¿Estas seguro de finalizar el proceo? Ya no podrá haber marcha atrás
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogFinish = false" :disabled="disBoton">Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="finalizarProcesoConCorte" :disabled="disBoton">Finalizar
                        proceso</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogFinishWithoutCut" max-width="800px" persistent>
            <v-card :loading="loading">
                <template slot="progress">
                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                </template>
                <v-card-title class="text-h5">¿Estas seguro de finalizar el proceo? Ya no podrá haber marcha atrás
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialogFinishWithoutCut" :disabled="disBoton">Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="finalizarProcesoSinCorte" :disabled="disBoton">Finalizar
                        proceso</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template>
            <v-snackbar v-model="alert" :timeout="tiempo_muestra" :value="true" :color="color_alerta" absolute right
                rounded="pill">
                <v-icon class="mr-3">
                    {{icono_alerta}}
                </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Ordenes de Producción Asigandas'
    },
    data: () => ({
        url_ruta: 'production_order_detail',
        url_ruta_piezas_procesos: "piece_process",
        url_ruta_update_production_order_detail: "update_production_order_detail",
        disComponente: "display: none;",
        tiempo_muestra: 1500,
        alert: false,
        texto_alerta: "",
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        dialogFinish: false,
        dialogFinishWithoutCut: false,
        disBoton: false,
        formTitle: "",
        titleProcess: "",
        loading: false,
        search: "",
        loader: true,
        loaderFinishProcess: false,
        encabezados: [
            {
                text: 'Orden de Producción',
                value: 'Orden de Producción',
            },
            {
                text: 'Maquina',
                value: 'machine',
            },
            { text: 'Pieza', value: 'piece' },
            { text: 'Unidad de Medida', value: 'unit' },
            { text: 'Categoria', value: 'piece_category' },
            { text: 'Cantidad de Producción', value: 'quantity', },
            { text: 'Proceso actual', value: 'process' },
            { text: 'Cantidad Correcta', value: 'quantity_correct' },
            { text: 'Cantidad Incorrecta', value: 'quantity_incorrect' },
            { text: 'Progreso', value: 'progress', width: '15%' },
            { text: 'SCRAP', value: 'scrap', width: '15%' },
            { text: 'P/M', value: 'quantity_evaluated_minute' },
            { text: 'P/P', value: 'proyection' },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        detalles: [],
        pieza_proceso: [],
        proceso_seleccionado: null,
        observaciones_proceso: "",
        idDetalle: 0,
        editedItem: {
            id: 0,
            piece: '',
            unit: '',
            piece_category: '',
            quantity: '',
            quantity_correct: '',
            process_id: 0
        },
        defaultItem: {
            id: 0,
            piece: '',
            unit: '',
            piece_category: '',
            quantity: '',
            quantity_correct: '',
            process_id: 0
        },
        error_messages: [],
        rules_validar: [(v) => v > 0 || "Se debe ingresar una cantidad mayor a 0"],
        rules_scrap: [(v) => v >= 0 || "No puede haber SCRAP negativo"],
        componentes_scrap: [],
        cantidad_realizada: 0,
        cantidad_no_realizada: 0,

    }),

    created() {
        this.consultarOrdenes()
    },

    watch: {
        dialogFinishWithoutCut(val) {
            val || this.closeDialogFinishWithoutCut();
        }
    },

    methods: {
        consultarOrdenes() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las areas de trabajo");
                });
        },

        consultarProcesosPieza(id) {
            service.buscarDatos(this.url_ruta_piezas_procesos, id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.pieza_proceso = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problema al consultar los procesos de la pieza: " + error);
                })
        },

        getColor(item) {
            let quantity = Number(item.quantity);
            let proyection = Number(item.proyection);
            let quantity_correct = Number(item.quantity_correct);

            if ((proyection + quantity_correct) < quantity) return 'red'
            else return 'green'
        },

        obtenerColorPorcentaje(item) {
            let color = "blue"
            let porcentaje = this.obtenerPorcenctaje(item);
            if (porcentaje <= 25) {
                color = "red";
            } else if (porcentaje <= 60) {
                color = "yellow";
            } else {
                color = "green";
            }

            return color;
        },

        close() {
            this.dialog = false
        },

        closeDialogFinishWithoutCut() {
            this.idDetalle = 0;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
            });
            this.dialogFinishWithoutCut = false;
        },

        obtenerPorcenctaje(item) {
            return (Number(item.quantity_correct) * 100) / Number(item.quantity);
        },

        obtenerPorcenctajeScrap(item) {
            return (Number(item.quantity_incorrect) * 100) / (Number(item.quantity));
        },

        obtenerColorPorcentajeScrap(item) {
            let color = "blue"
            let porcentaje = this.obtenerPorcenctajeScrap(item);
            if (porcentaje <= 10) {
                color = "green";
            } else if (porcentaje <= 15) {
                color = "yellow";
            } else {
                color = "red";
            }

            return color;
        },
        obtenerPiezaxHora(item) {

            return item.quantity_correct;
        },

        ingresarCantidadProduccion(item) {
            this.formTitle = "Orden de Planeación: " + item.id;
            this.titleProcess = "Proceso actual: " + item.process
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        mostrarElementos() {
            if (this.cantidad_no_realizada > 0) {
                this.disComponente = "";
                this.componentes_scrap = [(v) => !!v || "Valor requerido"];
            }
            else {
                this.disComponente = "display: none;";
                this.componentes_scrap = [];
                this.proceso_seleccionado = null;
                this.observaciones_proceso = "";
            }
        },

        showFinishProcess() {
            this.dialogFinish = true;
        },

        showFinishProcessWithoutCut(item) {
            this.idDetalle = item.production_order_detail_id;
            this.editedItem = Object.assign({}, item);
            this.dialogFinishWithoutCut = true;
        },

        finalizarProcesoSinCorte() {
            this.loaderFinishProcess = true;
            this.disBoton = true;
            service.modificarDatos(this.url_ruta_update_production_order_detail, this.editedItem, this.idDetalle).then((response) => {
                if (response.data.response_flag === 1) {
                    this.consultarOrdenes();
                    this.loading = false;
                    this.disBoton = false;
                    this.dialogFinishWithoutCut = false;
                    this.texto_alerta = "Se ha finalizado con éxito el proceso"
                    this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                    this.color_alerta = "success";
                    this.alert = true;
                }
                else {
                    this.consultarOrdenes();
                    this.loading = false;
                    this.disBoton = false;
                    this.dialogFinishWithoutCut = false;
                    this.texto_alerta = "Hubo un error al finalizar el proceso"
                    this.icono_alerta = "mdi-close-circle";
                    this.color_alerta = "red darken-2";
                    this.alert = true;
                }
            })
                .catch((error) => {
                    console.log("No se pudo consultar la orden: " + error);
                })
        },

        finalizarProcesoConCorte() {
            if (this.$refs.form.validate()) {
                if (this.cantidad_realizada <= (Number(this.editedItem.quantity) - Number(this.editedItem.quantity_correct))) {
                    this.loading = true;
                    this.disBoton = true;
                    this.error_messages = [];
                    this.editedItem.quantity_correct = this.cantidad_realizada;
                    this.editedItem.quantity_incorrect = this.cantidad_no_realizada;
                    this.editedItem.observations = this.observaciones_proceso;
                    this.editedItem.next_process = true;
                    service
                        .ingresarDatos(this.url_ruta, this.editedItem)
                        .then((response) => {
                            this.loading = false;
                            this.disBoton = false;
                            if (response.data.response_flag == 1) {
                                this.dialog = false;
                                this.cantidad_realizada = 0;
                                this.consultarOrdenes();
                            }
                        })
                        .catch(() => {
                            this.loading = false;
                            console.log("Problema al consultar evaluaciones de maquinas");
                        });
                } else {
                    this.error_messages = []
                    this.error_messages.push("La cantidad ingresada es mayor a la cantidad restante por producir");
                }
            }
        },

        save() {
            if (this.$refs.form.validate()) {
                if (this.cantidad_realizada <= (Number(this.editedItem.quantity) - Number(this.editedItem.quantity_correct))) {
                    this.loading = true;
                    this.disBoton = true;
                    this.error_messages = [];
                    this.editedItem.quantity_correct = this.cantidad_realizada;
                    this.editedItem.quantity_incorrect = this.cantidad_no_realizada;
                    this.editedItem.observations = this.observaciones_proceso;
                    this.editedItem.next_process = false;
                    console.log(this.editedItem);
                    service
                        .ingresarDatos(this.url_ruta, this.editedItem)
                        .then((response) => {
                            this.loading = false;
                            this.disBoton = false;
                            if (response.data.response_flag == 1) {
                                this.dialog = false;
                                this.cantidad_realizada = 0;
                                this.consultarOrdenes();
                            }
                        })
                        .catch(() => {
                            this.loading = false;
                            console.log("Problema al consultar evaluaciones de maquinas");
                        });
                } else {
                    this.error_messages = []
                    this.error_messages.push("La cantidad ingresada es mayor a la cantidad restante por producir");
                }
            }
        },
    },
}
</script>