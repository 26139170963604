<template>
    <div>
        <v-app id="inspire">
            <v-container fluid>
                <span style="font-size: 2.5em; ">
                    Panel de Evaluacion
                </span>
                <v-card elevation="20">
                    <!-- Encabezado de la card -->
                    <v-card-title class="justify-center" style="font-size: 3em;">
                        <p style="color: green; text-align: center;" class="mt-4">
                            <v-icon style="font-size: 1em; color:green">
                                timer
                            </v-icon>
                            {{ tiempo_trabajo }}
                        </p>
                        <v-spacer></v-spacer>
                        <span>
                            {{ machine.machine }}
                        </span>
                        <v-spacer></v-spacer>
                        <p style="color: orange; text-align: center;" class="mt-4">
                            <v-icon style="font-size: 1em; color:orange">
                                timer
                            </v-icon>
                            {{ tiempo_muerto }}
                        </p>
                    </v-card-title>
                    <v-divider></v-divider>
                    <!-- CUERPO DE LA CARD -->
                    <v-card-text>
                        <v-row>
                            <!-- CABECERA DE LA ORDEN -->
                            <v-col cols="12" v-show="orden_productividad_seleccionada">
                                <v-row>
                                    <v-col cols="8">
                                        <v-slide-group v-model="slider_detail_corts" center-active class="ma-2" show-arrows
                                        @click:next="avanzarCarrusel" @click:prev="retrocederCarrusel">
                                        <v-slide-item v-for="datos in detalles" :key="datos">
                                            <v-card elevation="3" height="115" width="250" class="ml-2">
                                                <v-card-title>
                                                    Corte No. {{ datos.count }}
                                                </v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text class="text-center"
                                                    style="font-size: 1.5em; color: black;">
                                                    {{ (Number(datos.quantity_correct) +
                                                            Number(datos.quantity_incorrect))
                                                    }}
                                                    piezas / % {{ ((Number(datos.quantity_correct) +
                                                            Number(datos.quantity_incorrect)) / Number(datos.quantity) *
                                                            100).toFixed(2)
                                                    }}
                                                </v-card-text>
                                            </v-card>
                                        </v-slide-item>
                                    </v-slide-group>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-card>
                                            <v-card-title class="justify-center" style="font-size: 1.35em;">
                                                Total planeacion
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text class="text-center" style="font-size: 1.5em; color: black;">
                                                {{selected_piece_from_planning_order.quantity}} piezas
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-card class="text-center">
                                            <v-card-title class="justify-center">
                                                Incidencias
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text style="font-size: 1.5em; color: black;">
                                                0 incidencias
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- BOTONES -->
                            <v-col cols="12" sm="12" md="2" lg="2" v-show="orden_productividad_seleccionada">
                                <v-row class="pl-2 ml-6">
                                    <v-col md="12">
                                        <v-btn elevation="24" fab :color="play_pause" width="135" height="135"
                                            @click="tomarTiempos">
                                            <v-icon x-large color="white">
                                                {{ icon_play_pause }}
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col md="12">
                                        <v-btn elevation="24" fab color="red" width="135" height="135">
                                            <v-icon x-large color="white">
                                                error
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col md="12">
                                        <v-btn elevation="24" fab color="info" width="135" height="135"
                                            @click="getProductionOrdersByMachine()">
                                            <v-icon x-large color="white">
                                                display_settings
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- BOTON INICIO -->
                            <v-col cols="12" sm="12" md="3" lg="3" v-show="!orden_productividad_seleccionada">
                                <v-row class="pl-6 ml-6">
                                    <v-col md="12">
                                        <v-btn elevation="24" fab color="info" width="150" height="150"
                                            @click="getProductionOrdersByMachine()">
                                            <v-icon x-large color="white">
                                                display_settings
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- FORMULARIO -->
                            <v-col cols="12" sm="12" md="4" lg="4" v-show="orden_productividad_seleccionada">
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field v-model="selected_piece_from_planning_order.piece" label="Pieza"
                                            readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="selected_piece_from_planning_order.unit"
                                            label="Unidad de Medida" readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="orden_planeacion_seleccionada.piece_category"
                                            label="Categoria de Pieza" readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-progress-linear :color=obtenerColorPorcentaje() height="30"
                                            :value=obtenerPorcenctaje() striped>
                                            <template v-slot:default="{ value }">
                                                <strong>{{ Math.ceil(value) }}%</strong>
                                            </template>
                                        </v-progress-linear>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-progress-linear :color=obtenerColorPorcentajeScrap() height="30"
                                            :value=obtenerPorcenctajeScrap() striped>
                                            <template v-slot:default="{ value }">
                                                <strong>{{ Math.ceil(value) }}%</strong>
                                            </template>
                                        </v-progress-linear>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="cantidad_realizada" label="Cantidad Realizada"
                                            :loading="loader" :disabled="!guardar_evaluacion" type="number"
                                            :error-messages="error_messages">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="cantidad_no_realizada" label="SCRAP" type="number"
                                            :loading="loader" :disabled="!guardar_evaluacion">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" class="pt-4">
                                        <v-btn color="blue-grey" v-show="!guardar_evaluacion" class="ma-2 white--text"
                                            :disabled="play_pause == 'green'" @click="guardar_evaluacion = true">
                                            Agregar
                                            <v-icon right dark>
                                                add
                                            </v-icon>
                                        </v-btn>
                                        <v-btn color="green" v-show="guardar_evaluacion" class="ma-2 white--text"
                                            @click="guardar_evaluacion_servidor()">
                                            Guardar
                                            <v-icon right dark>
                                                mdi-content-save
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" v-show="orden_productividad_seleccionada">
                                <v-row>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-card-title>
                                                Mejor corte
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                a
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card>
                                            <v-card-title>
                                                Peor corte
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                a
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <template slot="progress">
                        <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                    </template>
                </v-card>
            </v-container>
            <v-dialog v-model="dialogStopType" width="1000" persistent>
                <v-card>
                    <v-card-title class="justify-center" style="font-size: 2.5em;">
                        <span>
                            TIPO PARO
                        </span>
                    </v-card-title>
                    <v-card-text class="pt-6 mt-6">
                        <v-container fluid>
                            <v-row class="pb-6">
                                <v-btn elevation="20" width="130" height="80" rounded @click="seleccionParo(2, 2, null)"
                                    :disabled="disBoton">
                                    <v-icon left large>
                                        wc
                                    </v-icon>
                                    <span>
                                        BAÑO
                                    </span>
                                </v-btn>
                                <v-spacer>
                                </v-spacer>
                                <v-btn elevation="20" width="130" height="80" color="orange" rounded
                                    @click="seleccionParo(2, 1, null)" :disabled="disBoton">
                                    <v-icon left large>
                                        lunch_dining
                                    </v-icon>
                                    <span>
                                        COMIDA
                                    </span>
                                </v-btn>
                                <v-spacer>
                                </v-spacer>
                                <v-btn elevation="20" width="150" height="80" color="teal" rounded
                                    @click="mostrarComponentes" :disabled="disBoton">
                                    <v-icon left large>
                                        help
                                    </v-icon>
                                    <span>
                                        NO DEFINIDA
                                    </span>
                                </v-btn>
                                <v-spacer>
                                </v-spacer>
                                <v-btn elevation="20" width="150" height="80" color="red" rounded
                                    @click="closeDialogStopType">
                                    <v-icon left large>
                                        cancel
                                    </v-icon>
                                    <span>
                                        CANCELAR
                                    </span>
                                </v-btn>
                            </v-row>
                            <v-row class="pt-5 mt-6" :style="ocultarComponentes">
                                <v-col cols="7">
                                    <v-text-field v-model="observaciones"
                                        @input="observaciones = observaciones.toUpperCase()"
                                        label="Descripción del paro" style="font-size: 2em;">
                                    </v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <v-btn elevation="20" rounded width="150" height="80" class="pink white--text"
                                        @click="volverAtras">
                                        <v-icon left large>
                                            arrow_back
                                        </v-icon>
                                        ATRÁS
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn elevation="20" rounded width="150" height="80" class="blue white--text"
                                        @click="seleccionParo(2, 3, observaciones)">
                                        <v-icon left large>
                                            save
                                        </v-icon>
                                        GUARDAR
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog_select_piece" persistent>
                <div v-if="loader">
                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
                <div v-else>
                    <v-card>
                        <v-card-text class="pt-6 mt-6">
                            <v-data-table :headers="encabezados_planeacion" :items="details_piece_planning_order"
                                class="elevation-1">
                                <template v-slot:item.actions="{ item }">
                                    <v-icon class="mr-2" @click="selectPieceFromPlanningOrder(item)">
                                        checklist
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="blue darken-1" text @click="backDialogSelectPlanningOrder">
                                Regresar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-dialog>
            <v-dialog v-model="dialogSelectPlanning" persistent>
                <div v-if="loader_detail">
                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
                <div v-else>
                    <v-card>
                        <v-card-title class="justify-center">
                            <span style="font-size: 2em;">
                                SELECCIÓN DE ORDEN DE PLANEACIÓN
                            </span>
                        </v-card-title>
                        <v-card-text class="mt-6">
                            <v-data-table :headers="headers_order_planning" :items="details_order_planning"
                                class="elevation-1">
                                <template v-slot:item.actions_order_planning="{ item }">
                                    <v-icon class="mr-2" large color="buttonEdit"
                                        @click="seleccionarOrdenPlaneacion(item)">
                                        keyboard_tab
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDialogSelectPlanning">
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Panel de evaluación'
    },
    data: () => ({
        url_ruta: "production_order_work_time",
        url_ruta_get_production_orders_by_machine: "get_production_orders_by_machine",
        url_ruta_get_piece_from_planning_order: "get_piece_from_planning_order",
        url_ruta_get_detail_from_planning_order: "get_detail_from_planning_order",
        encabezado: [
            { text: 'Fecha', value: 'evaluation_date' },
            { text: 'Hora', value: 'evaluation_time' },
            { text: 'Cantidad Correcta', value: 'quantity_correct' },
            { text: 'Cantidad Incorrecta', value: 'quantity_incorrect', },
            { text: 'Turno', value: 'turn', },
            { text: 'Empleado', value: 'employee', },
        ],
        detalles: [],
        encabezados_planeacion: [
            { text: 'Pieza', value: 'piece' },
            { text: 'Unidad de Medida', value: 'unit' },
            { text: 'Cantidad Planeada', value: 'quantity', },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        headers_order_planning: [
            { text: 'Área de Trabajo', value: 'work_area' },
            { text: 'Línea de Producción', value: 'production_line' },
            { text: 'Fecha Inicio', value: 'start_date' },
            { text: 'Fecha Fin', value: 'expected_end_date' },
            { text: 'Hora Inicio', value: 'start_time' },
            { text: 'Fecha Fin', value: 'expected_end_time' },
            { text: 'Acciones', value: 'actions_order_planning', sortable: false }
        ],
        error_messages: [],
        details_order_planning: [],
        detalles_planeacion: [],
        search: "",
        play_pause: "green",
        icon_play_pause: "play_arrow",
        tiempo_trabajo: "00:00:00",
        tiempo_muerto: "00:00:00",
        centecimas_trabajo: 0,
        segundos_trabajo: 0,
        minutos_trabajo: 0,
        horas_trabajo: 0,
        centecimas_muerto: 0,
        segundos_muerto: 0,
        minutos_muerto: 0,
        horas_muerto: 0,
        segundos_actual: 0,
        minutos_actual: 0,
        horas_actual: 0,
        cantidad_realizada: 0,
        cantidad_no_realizada: 0,
        corriendo_muerto: null,
        corriendo: null,
        corriendo_hora: null,
        hora_actual: "",
        dialogStopType: false,
        observaciones: "",
        ocultarComponentes: "display: none;",
        disBoton: false,
        orden_productividad_seleccionada: false,
        loader: false,
        loader_detail: false,
        orden_planeacion_seleccionada: {
            piece: "N/S",
            machine: "N/S",
            production_line: "N/S",
            unit: "N/S",
            piece_category: "N/S",
            quantity: "N/S",
            quantity_correct: "N/S",
            quantity_incorrect: "N/S",
        },
        guardar_evaluacion: false,
        ocultar_componente: "display: none;",
        dialogSelectPlanning: false,
        dialog_select_piece: false,
        selected_order_planning: [],
        details_piece_planning_order: [],
        selected_piece_from_planning_order: [],
        machine:{
            id: 3,
            machine: "Esmerilador M5525"
        },
        slider_detail_corts: 0,
    }),

    watch: {
        closeDialogStopType(val) {
            val || this.closeDialogStopType();
        },
        closeDialogSelectPlanning(val) {
            val || this.closeDialogSelectPlanning();
        }
    },

    mounted() {

    },

    created() {

    },

    methods: {
        tomarTiempos() {
            if (this.play_pause === "green") {
                this.iniciarTiempo();
                this.detenerMuerto();
                this.mandarTiempos(1, null, null);
                this.play_pause = "orange";
                this.icon_play_pause = "pause";
            }
            else {
                this.abrirDialogStopType();
                this.play_pause = "green";
                this.icon_play_pause = "play_arrow";
            }
        },

        horaActual() {
            let tiempo_actual = new Date();
            this.horas_actual = tiempo_actual.getHours();
            this.minutos_actual = tiempo_actual.getMinutes();
            this.segundos_actual = tiempo_actual.getSeconds();

            const sumarHora = () => {
                if (this.horas_actual < 99) {
                    this.horas_actual++;
                }
            };

            const sumarMinuto = () => {
                if (this.minutos_actual === 59) {
                    this.minutos_actual = 0;
                    sumarHora();
                }
                else {
                    this.minutos_actual++;
                }
            };

            const incrementar = () => {
                if (this.segundos_actual === 59) {
                    this.segundos_actual = 0;
                    sumarMinuto();
                }
                else {
                    this.segundos_actual++;
                }
                this.dibujarTiempoActual();
            }

            this.corriendo_hora = setInterval(incrementar, 1000);
        },

        detener() {
            clearInterval(this.corriendo);
            this.corriendo = null;
        },

        detenerMuerto() {
            clearInterval(this.corriendo_muerto);
            this.corriendo_muerto = null;
        },

        obtenerColorPorcentaje() {
            let color = "blue"
            let porcentaje = this.obtenerPorcenctaje();
            if (porcentaje <= 25) {
                color = "red";
            } else if (porcentaje <= 60) {
                color = "yellow";
            } else {
                color = "green";
            }

            return color;
        },

        obtenerPorcenctaje() {
            return (Number(this.orden_planeacion_seleccionada.quantity_correct) * 100) / Number(this.orden_planeacion_seleccionada.quantity);
        },

        obtenerPorcenctajeScrap() {
            return (Number(this.orden_planeacion_seleccionada.quantity_incorrect) * 100) / Number(this.orden_planeacion_seleccionada.quantity);
        },

        obtenerColorPorcentajeScrap() {
            let color = "blue"
            let porcentaje = this.obtenerPorcenctajeScrap();
            if (porcentaje <= 10) {
                color = "green";
            } else if (porcentaje <= 15) {
                color = "yellow";
            } else {
                color = "red";
            }

            return color;
        },

        consultarOrdenes() {
            this.loader = true;
            this.dialog_seleccionar_planeacion = true;
            service
                .consultarDatos("production_order_detail")
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles_planeacion = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar las areas de trabajo");
                });
        },

        seleccionarOrdenPlaneacion(item) {
            this.selected_order_planning = item;
            this.dialogSelectPlanning = false;
            this.getPieceFromPlanningOrder(this.selected_order_planning.id);
        },

        selectPieceFromPlanningOrder(item) {
            this.selected_piece_from_planning_order = item;
            this.dialog_select_piece = false;
            this.getDetailFromPlanningOrder();
            this.orden_productividad_seleccionada = true;
        },

        iniciarTiempo() {
            const sumarHora = () => {
                if (this.horas_trabajo < 99) {
                    this.horas_trabajo++;
                }
            };

            const sumarMinuto = () => {
                if (this.minutos_trabajo === 59) {
                    this.minutos_trabajo = 0;
                    sumarHora();
                }
                else {
                    this.minutos_trabajo++;
                }
            };

            const sumarSegundo = () => {
                if (this.segundos_trabajo === 59) {
                    this.segundos_trabajo = 0;
                    sumarMinuto();
                }
                else {
                    this.segundos_trabajo++;
                }
            };

            const incrementar = () => {
                if (this.centecimas_trabajo === 99) {
                    this.centecimas_trabajo = 0;
                    sumarSegundo();
                }
                else {
                    this.centecimas_trabajo++;
                }

                this.dibujarTiempo();
            };
            this.corriendo = setInterval(incrementar, 10);
        },

        iniciarTiempoMuerto() {
            const sumarHora = () => {
                if (this.horas_muerto < 99) {
                    this.horas_muerto++;
                }
            };

            const sumarMinuto = () => {
                if (this.minutos_muerto === 59) {
                    this.minutos_muerto = 0;
                    sumarHora();
                }
                else {
                    this.minutos_muerto++;
                }
            };

            const sumarSegundo = () => {
                if (this.segundos_muerto === 59) {
                    this.segundos_muerto = 0;
                    sumarMinuto();
                }
                else {
                    this.segundos_muerto++;
                }
            };

            const incrementar = () => {
                if (this.centecimas_muerto === 99) {
                    this.centecimas_muerto = 0;
                    sumarSegundo();
                }
                else {
                    this.centecimas_muerto++;
                }

                this.dibujarTiempoMuerto();
            };

            this.corriendo_muerto = setInterval(incrementar, 10);
        },

        dibujarTiempo() {
            this.tiempo_trabajo = this.horas_trabajo + ":" + this.minutos_trabajo + ":" + this.segundos_trabajo + ":" + this.centecimas_trabajo;
        },

        dibujarTiempoMuerto() {
            this.tiempo_muerto = this.horas_muerto + ":" + this.minutos_muerto + ":" + this.segundos_muerto + ":" + this.centecimas_muerto;
        },

        dibujarTiempoActual() {
            this.hora_actual = this.horas_actual + ":" + this.minutos_actual + ":" + this.segundos_actual;
        },

        mostrarComponentes() {
            this.ocultarComponentes = "";
            this.disBoton = true;
        },

        volverAtras() {
            this.disBoton = false;
            this.ocultarComponentes = "display:none;"
            this.observaciones = "";
        },

        abrirDialogStopType() {
            this.dialogStopType = true;
        },

        seleccionParo(checkType, stopType, observations) {
            this.play_pause = "green";
            this.icon_play_pause = "play_arrow";
            this.detener();
            this.mandarTiempos(checkType, stopType, observations);
            this.iniciarTiempoMuerto();
            this.dialogStopType = false;
        },

        closeDialogStopType() {
            this.dialogStopType = false;
            this.play_pause = "orange";
            this.icon_play_pause = "pause";
            this.detenerMuerto();
        },

        async mandarTiempos(checkType, stopType, _observations) {
            let tiempo = new Date();
            //let compania = JSON.parse(localStorage.getItem("company"));
            let usuario = JSON.parse(localStorage.getItem("usuario"));
            let data = {
                check_date: tiempo.toJSON().slice(0, 10) + " " + tiempo.getHours() + ":" + tiempo.getMinutes() + ":" + tiempo.getSeconds(),
                company_id: 7,
                user_id: usuario.id,
                stop_type_id: stopType,
                check_type_id: checkType,
                observations: _observations
            };

            service.ingresarDatos(this.url_ruta, data).then((response) => {
                if (response.data.respone_flag === 1) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        closeDialogSelectPlanning() {
            this.details_order_planning = [];
            this.dialogSelectPlanning = false;
        },

        backDialogSelectPlanningOrder() {
            this.dialog_select_piece = false;
            this.getProductionOrdersByMachine();
        },

        guardar_evaluacion_servidor() {
            if (this.cantidad_realizada > 0) {
                if (this.cantidad_realizada <= (Number(this.orden_planeacion_seleccionada.quantity) - Number(this.orden_planeacion_seleccionada.quantity_correct))) {
                    this.loader = true;
                    this.error_messages = [];
                    let orden_planeacion_seleccionada_original = Object.assign({}, this.orden_planeacion_seleccionada);
                    orden_planeacion_seleccionada_original.quantity_correct = this.cantidad_realizada;
                    orden_planeacion_seleccionada_original.quantity_incorrect = this.cantidad_no_realizada;
                    orden_planeacion_seleccionada_original.observations = this.observaciones_proceso;
                    orden_planeacion_seleccionada_original.next_process = false;
                    service
                        .ingresarDatos("production_order_detail", orden_planeacion_seleccionada_original)
                        .then((response) => {
                            if (response.data.response_flag == 1) {
                                this.orden_planeacion_seleccionada.quantity_correct = Number(orden_planeacion_seleccionada_original.quantity_correct) + Number(this.orden_planeacion_seleccionada.quantity_correct);
                                this.orden_planeacion_seleccionada.quantity_incorrect = Number(orden_planeacion_seleccionada_original.quantity_incorrect) + Number(this.orden_planeacion_seleccionada.quantity_incorrect);
                                this.cantidad_realizada = 0;
                                this.cantidad_no_realizada = 0;
                                this.guardar_evaluacion = false;
                                this.consultarOrdenesEvaluaciones();
                            }
                            this.loader = false;
                        });
                } else {
                    this.error_messages = []
                    this.error_messages.push("La cantidad ingresada es mayor a la cantidad restante por producir");
                }
            } else {
                this.error_messages = []
                this.error_messages.push("La cantidad ingresada debe ser mayor a 0");
            }
        },

        getProductionOrdersByMachine() {
            this.loader_detail = true;
            this.dialogSelectPlanning = true;
            service.buscarDatos(this.url_ruta_get_production_orders_by_machine, this.machine.id).then((response) => {
                this.loader_detail = false;
                if (response.data.response_flag === 1) {
                    this.details_order_planning = response.data.response;
                }
            }).catch((error) => {
                console.log("Hubo un problema al consultar las ordenes de planeación" + error);
            })
        },

        getPieceFromPlanningOrder(id) {
            this.loader = true;
            this.dialog_select_piece = true;
            service.buscarDatos(this.url_ruta_get_piece_from_planning_order, id).then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.details_piece_planning_order = response.data.response;
                }
            }).catch((error) => {
                console.log("Hubo un problema al consultar las piezas de la orden de planeacion" + error);
            });
        },

        getDetailFromPlanningOrder() {
            service.buscarDatos(this.url_ruta_get_detail_from_planning_order, this.selected_piece_from_planning_order.id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.detalles = response.data.response;
                }
            }).catch((error) => {
                console.log("Problemas al consultar el detalle de la orden de produccion" + error);
            });
        },

        avanzarCarrusel() {
            this.carrusel++;
            this.carrusel == this.detalles.length ? (this.carrusel = 0) : "";
        },

        retrocederCarrusel() {
            this.carrusel--;
        }
    }
}
</script>