<template>
  <v-app id="session">
    <div v-if="errored">
      <v-alert :type="type">
        {{ message }}
      </v-alert>
    </div>

    <v-container id="signinup-form" class="fill-height">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" sm="8" md="8">
          <v-card class="elevation-15 card" :loading="loading">
            <template slot="progress">
              <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
            </template>
            <v-window v-model="step">
              <!-- Inicio de Sesión-->
              <v-window-item :value="1">
                <v-row class>
                  <v-col cols="12" md="4" class="vcenter" :class="`${bgColor}`">
                    <div>
                      <v-layout mt-4 column align-center>
                        <v-flex>
                          <v-img height="200" width="200" :src="require('@/assets/login.png')"></v-img>
                        </v-flex>
                      </v-layout>
                      <v-card-text>
                        <h1 class="text-center headline mb-3 white--text">
                          SIET
                        </h1>
                        <h5 class="text-center overline mb-3 white--text">
                          CONTROL DE PRODUCCIÓN
                        </h5>
                      </v-card-text>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" class="pt-6 pb-6">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-card-text>
                        <h1 class="text-center display-1 mb-10" :class="`${bgColor}--text`">
                          Iniciar sesión
                        </h1>
                        <v-text-field v-model="usuario" label="Usuario" append-icon="mdi-account" type="text"
                          :rules="rules_login" color="primary" />
                        <v-text-field :append-icon="
                          login_password_show ? 'mdi-eye' : 'mdi-eye-off'
                        " :type="login_password_show ? 'text' : 'password'" v-model="contrasena" label="Contraseña"
                          :rules="rules_login" v-on:keyup.enter="iniciar_sesion" @click:append="
                            login_password_show = !login_password_show
                          " color="primary" />
                        <div class="text-center mt-6">
                          <v-btn large color="primary" dark @click="iniciar_sesion">Entrar</v-btn>
                        </div>
                      </v-card-text>
                    </v-form>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import service from "@/core/Service.js";
export default {
  metaInfo: {
    title: 'Inicio de Sesión'
  },
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "primary",
    },
    expressColor: {
      type: String,
      default: "error",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  created() {
    this.inicializar();
  },
  data: () => ({
    footer: {
      inset: true,
    },
    step: 1,
    login_password_show: false,
    register_show_password: false,
    register_show_confirm_password: false,
    message: "",
    valid: false,
    errored: false,
    loading: false,
    contrasena: "root",
    usuario: "jsorcia@sdib.com.mx",
    type: "error",
    rules_login: [(v) => !!v || "Campo Requerido"],
  }),
  mounted() { },
  methods: {
    inicializar() {
      this.usuario_actual = JSON.parse(localStorage.getItem('usuario'));
      if (localStorage.getItem("usuario") != null) {
        this.$router.push("/CompaniaSeleccion");
      }
    },
    cerrarSesion() {
      localStorage.removeItem("usuario");
      this.$router.push("/");
    },
    iniciar_sesion() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let params = {
          password: this.contrasena,
          email: this.usuario
        };
        service
          .login(params)
          .then((response) => {
            this.loading = false;
            if (response.data.response_flag == 1) {
              localStorage.setItem("usuario", JSON.stringify(response.data.response));
              this.$router.push("inicio");
              /*
              if (response.data.response.employee_type_id === 1) {
               
              }
              else{
                localStorage.setItem("usuario", JSON.stringify(response.data.response));
                this.$router.push("PanelControl");
              }
              */
            } else {
              this.errored = true;
              this.message = "Usuario o Contraseña incorrectos";
              setTimeout(() => (this.errored = false), 1000);
            }
          })
          .catch(() => {
            this.loading = false;
            this.errored = true;
            this.message =
              "Ocurrio un problema en el sitio, comunicarse con el encargado de sistemas";
              setTimeout(() => (this.errored = false), 1000);
          });
      }
    },
  },
};
</script>