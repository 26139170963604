<template>
    <div>
        <v-card>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="10" md="10">
                        <v-tabs show-arrows v-model="mes_selecionado" @change="consultarOrdenesEvaluaciones">
                            <v-tab v-for="detalle in meses" :key="detalle.id">
                                {{ detalle.mes }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                        <v-select class="" v-model="anio_selecionado" :items="anios_disponibles" label="Año" solo dense>
                        </v-select>
                    </v-col>

                </v-row>
            </v-container>
        </v-card>
        <div v-if="loader">
            <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <Bar :chart-options="chartOptionPiezasRealizadas" :chart-data="chartDataPiezasRealizadas"
                            chart-id="bar-chart" :dataset-id-key="datasetIdKey" :plugins="plugins"
                            :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <Bar :chart-options="chartOptionPiezasRealizadas" :chart-data="chartDataMaquinasPiezas"
                            chart-id="bar-chart" :dataset-id-key="datasetIdKey" :plugins="plugins"
                            :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <LineChartGenerator :chart-options="chartOptionPiezasRealizadas"
                            :chart-data="chartDataDiasPiezas" chart-id="bar-chart" :dataset-id-key="datasetIdKey"
                            :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
                            :height="height" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <Pie :chart-options="chartOptionPiezasRealizadas" :chart-data="chartDataPorcentajesPiezas"
                            chart-id="pie-chart" :dataset-id-key="datasetIdKey" :plugins="plugins"
                            :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <Bar :chart-options="chartOptionPiezasRealizadas" :chart-data="chartDataScrapProceso"
                            chart-id="bar-chart" :dataset-id-key="datasetIdKey" :plugins="plugins"
                            :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
  
<script>
import service from "@/core/Service.js";
import { Bar } from 'vue-chartjs/legacy'
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Pie } from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LineElement,
    LinearScale,
    ArcElement,
    PointElement
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement, PointElement)

export default {
    metaInfo: {
        title: 'Productividad'
    },
    name: 'BarChart',
    components: {
        Bar,
        LineChartGenerator,
        Pie
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 325
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        mes_selecionado: null,
        anio_selecionado: null,
        loader: false,
        chartDataPiezasRealizadas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: '#00838f',
                    data: []
                },
                {
                    label: 'SCRAP',
                    backgroundColor: '#ff5f52',
                    data: []
                }
            ]
        },
        chartDataScrapProceso:{
            labels:[],
            datasets:[
                { label: 'SCRAP', backgroundColor: '#ff5f52', data: [] }
            ]
        },
        chartOptionPiezasRealizadas: {
            responsive: true,
            maintainAspectRatio: false
        },


        chartDataMaquinasPiezas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: '#bdbdbd',
                    data: []
                }, {
                    label: 'SCRAP',
                    backgroundColor: '#ff5f52',
                    data: []
                }
            ]
        },

        chartDataDiasPiezas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Producidas',
                    backgroundColor: '#bdbdbd',
                    data: []
                },
                {
                    label: 'SCRAP',
                    backgroundColor: '#ff5f52',
                    data: []
                }
            ]
        },
        chartDataAreasPiezas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: '#fff59d',
                    data: []
                }
            ]
        },

        chartDataPorcentajesPiezas: {
            labels: [

            ],
            datasets: [
                {
                    label: 'Piezas Realizadas',
                    backgroundColor: ['#00838f', '#E46651', '#00D8FF', '#DD1B16'],
                    data: []
                }
            ]
        },
        chartOptionsMaquinasPiezas: {
            responsive: true,
            maintainAspectRatio: false
        },
        meses: [
            { id: 0, mes: "Ene" },
            { id: 1, mes: "Feb" },
            { id: 2, mes: "Mar" },
            { id: 3, mes: "Abr" },
            { id: 4, mes: "May" },
            { id: 5, mes: "Jun" },
            { id: 6, mes: "Jul" },
            { id: 7, mes: "Ago" },
            { id: 8, mes: "Sep" },
            { id: 9, mes: "Oct" },
            { id: 10, mes: "Nov" },
            { id: 11, mes: "Dic" },
        ],
        anios_disponibles: [2022, 2021, 2020, 2019],
    }),
    created() {
        this.inicializarComponentes();
    },
    methods: {
        inicializarComponentes() {
            let fecha_actual = new Date();
            this.mes_selecionado = fecha_actual.getMonth();
            this.anio_selecionado = fecha_actual.getFullYear();
            this.consultarOrdenesEvaluaciones();
        },

        consultarOrdenesEvaluaciones() {
            this.loader = true;
            let params = new Object();
            params.mes_seleccionado = this.mes_selecionado + 1;
            service
                .ingresarDatos("production_order_evaluation", params)
                .then((response) => {
                    if (response.data.response_flag == 1) {
                        this.loader = false;
                        this.configurarPiezasTotales(response.data.response);
                        this.configurarPiezasMaquinasTotales(response.data.response);
                        this.configurarPiezasDiasTotales(response.data.response);
                        this.configurarPiezasAreasTotales(response.data.response);
                        this.configurarPiezasTurnosTotales(response.data.response);
                        this.configurarScrapTotal(response.data.response);
                        console.log(response.data.response);
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar la información de la gráfica");
                });
        },

        configurarPiezasTotales(response) {
            let piezas_seleccionadas = new Array();
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let pieza_index_seleccionada = piezas_seleccionadas.findIndex(element => element.piece_id == evaluacion_seleccionada.piece_id);
                //Si existe incrementamos la cantidad realizada
                if (pieza_index_seleccionada > -1) {
                    piezas_seleccionadas[pieza_index_seleccionada].cantidad_realizada = (Number(piezas_seleccionadas[pieza_index_seleccionada].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                    piezas_seleccionadas[pieza_index_seleccionada].cantidad_no_realizada = (Number(piezas_seleccionadas[pieza_index_seleccionada].cantidad_no_realizada) + Number(evaluacion_seleccionada.quantity_incorrect));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    evaluacion_seleccionada.cantidad_no_realizada = evaluacion_seleccionada.quantity_incorrect;
                    piezas_seleccionadas.push(evaluacion_seleccionada);
                }
            });

            this.chartDataPiezasRealizadas.labels = []
            this.chartDataPiezasRealizadas.datasets[0].data = []
            this.chartDataPiezasRealizadas.datasets[1].data = []

            piezas_seleccionadas.forEach(pieza_seleccionada => {
                this.chartDataPiezasRealizadas.labels.push(pieza_seleccionada.piece);
                this.chartDataPiezasRealizadas.datasets[0].data.push(pieza_seleccionada.cantidad_realizada);
                this.chartDataPiezasRealizadas.datasets[1].data.push(pieza_seleccionada.cantidad_no_realizada);
            });
        },

        configurarScrapTotal(response){
            let procesos_encontrados = new Array();
            //Obtener los procesos dentro de la consulta
            response.forEach(evaluacion_seleccionada =>{
                let proceso_index_seleccionado = procesos_encontrados.findIndex(element => element.process_id == evaluacion_seleccionada.process_id);
                if(proceso_index_seleccionado > -1){
                    procesos_encontrados[proceso_index_seleccionado].scrap = (Number(procesos_encontrados[proceso_index_seleccionado].scrap) + Number(evaluacion_seleccionada.quantity_incorrect));
                }
                else{
                    evaluacion_seleccionada.scrap = evaluacion_seleccionada.quantity_incorrect;
                    procesos_encontrados.push(evaluacion_seleccionada);
                }
            });

            this.chartDataScrapProceso.labels = [];
            this.chartDataScrapProceso.datasets[0].data = [];

            procesos_encontrados.forEach(proceso_seleccionado =>{
                this.chartDataScrapProceso.labels.push(proceso_seleccionado.process);
                this.chartDataScrapProceso.datasets[0].data.push(proceso_seleccionado.scrap);
            });
        },

        configurarPiezasMaquinasTotales(response) {

            let maquinas_seleccionadas = new Array();
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let maquina_index_seleccionada = maquinas_seleccionadas.findIndex(element => element.machine_id == evaluacion_seleccionada.machine_id);
                //Si existe incrementamos la cantidad realizada
                if (maquina_index_seleccionada > -1) {
                    maquinas_seleccionadas[maquina_index_seleccionada].cantidad_realizada = (Number(maquinas_seleccionadas[maquina_index_seleccionada].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                    maquinas_seleccionadas[maquina_index_seleccionada].cantidad_no_realizada = (Number(maquinas_seleccionadas[maquina_index_seleccionada].cantidad_no_realizada) + Number(evaluacion_seleccionada.quantity_incorrect));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    evaluacion_seleccionada.cantidad_no_realizada = evaluacion_seleccionada.quantity_incorrect;
                    maquinas_seleccionadas.push(evaluacion_seleccionada);
                }
            });

            this.chartDataMaquinasPiezas.labels = []
            this.chartDataMaquinasPiezas.datasets[0].data = []
            this.chartDataMaquinasPiezas.datasets[1].data = []
            maquinas_seleccionadas.forEach(maquina_seleccionada => {
                this.chartDataMaquinasPiezas.labels.push(maquina_seleccionada.machine);
                this.chartDataMaquinasPiezas.datasets[0].data.push(maquina_seleccionada.cantidad_realizada);
                this.chartDataMaquinasPiezas.datasets[1].data.push(maquina_seleccionada.cantidad_no_realizada);
            });
        },

        configurarPiezasDiasTotales(response) {
            let dias_seleccionadas = new Array();
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let dia_seleccionado_index = dias_seleccionadas.findIndex(element => element.evaluation_date === evaluacion_seleccionada.evaluation_date);
                //Si existe incrementamos la cantidad realizada
                if (dia_seleccionado_index > -1) {
                    dias_seleccionadas[dia_seleccionado_index].cantidad_realizada = (Number(dias_seleccionadas[dia_seleccionado_index].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                    dias_seleccionadas[dia_seleccionado_index].cantidad_no_realizada = (Number(dias_seleccionadas[dia_seleccionado_index].cantidad_no_realizada) + Number(evaluacion_seleccionada.quantity_incorrect));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    evaluacion_seleccionada.cantidad_no_realizada = evaluacion_seleccionada.quantity_incorrect;
                    dias_seleccionadas.push(evaluacion_seleccionada);
                }
            });

            this.chartDataDiasPiezas.labels = []
            this.chartDataDiasPiezas.datasets[0].data = []
            this.chartDataDiasPiezas.datasets[1].data = []
            dias_seleccionadas.forEach(dia_seleccionado => {
                this.chartDataDiasPiezas.labels.push(dia_seleccionado.evaluation_date);
                this.chartDataDiasPiezas.datasets[0].data.push(dia_seleccionado.cantidad_realizada);
                this.chartDataDiasPiezas.datasets[1].data.push(dia_seleccionado.cantidad_no_realizada);
            });
        },
        configurarPiezasAreasTotales(response) {
            let areas_seleccionadas = new Array();
            //Recorremos la información
            response.forEach(evaluacion_seleccionada => {
                //Revisamos si existe ya en las piezas
                let area_seleccionado_index = areas_seleccionadas.findIndex(element => element.work_area_id === evaluacion_seleccionada.work_area_id);
                //Si existe incrementamos la cantidad realizada
                if (area_seleccionado_index > -1) {
                    areas_seleccionadas[area_seleccionado_index].cantidad_realizada = (Number(areas_seleccionadas[area_seleccionado_index].cantidad_realizada) + Number(evaluacion_seleccionada.quantity_correct));
                } else {
                    evaluacion_seleccionada.cantidad_realizada = evaluacion_seleccionada.quantity_correct;
                    areas_seleccionadas.push(evaluacion_seleccionada);
                }
            });

            this.chartDataAreasPiezas.labels = []
            this.chartDataAreasPiezas.datasets[0].data = []
            areas_seleccionadas.forEach(area_seleccionado => {
                this.chartDataAreasPiezas.labels.push(area_seleccionado.work_area);
                this.chartDataAreasPiezas.datasets[0].data.push(area_seleccionado.cantidad_realizada);
            });
        },
        configurarPiezasTurnosTotales(response) {
            //Obtenemos los totales de piezas realizadas 
            let piezas_realizadas = 0;
            let porcentaje_piezas_realizadas = 0;
            let piezas_no_realizadas = 0;
            let piezas_totales = 0;
            let porcentaje_piezas_no_realizadas = 0;
            response.forEach(evaluacion_seleccionada => {
                piezas_realizadas = piezas_realizadas + Number(evaluacion_seleccionada.quantity_correct);
                piezas_no_realizadas = piezas_no_realizadas + Number(evaluacion_seleccionada.quantity_incorrect);
            });
            piezas_totales = piezas_realizadas + piezas_no_realizadas;

            porcentaje_piezas_realizadas = (Number(piezas_realizadas) * 100) / Number(piezas_totales);
            porcentaje_piezas_no_realizadas = (Number(piezas_no_realizadas) * 100) / Number(piezas_totales);

            console.log(porcentaje_piezas_no_realizadas,porcentaje_piezas_realizadas);
            this.chartDataPorcentajesPiezas.labels = ["Piezas Realizadas" , "SCRAP"]
            this.chartDataPorcentajesPiezas.datasets[0].data = [];
            this.chartDataPorcentajesPiezas.datasets[0].data.push(porcentaje_piezas_realizadas);
            this.chartDataPorcentajesPiezas.datasets[0].data.push(porcentaje_piezas_no_realizadas);
        }
    }
}
</script>
  