<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="article, actions">
        </v-skeleton-loader>
    </div>
    <div v-else>
        <template>
            <v-toolbar flat>
                <v-toolbar-title>Configuración</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer>
                </v-spacer>
                <v-dialog v-model="dialogSave" persistent max-width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            <v-icon left>
                                save
                            </v-icon>
                            Guardar
                        </v-btn>
                    </template>
                    <v-card :loading="loading">
                        <template slot="progress">
                            <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                        </template>
                        <v-card-title>
                            <span class="text-h5">
                                Estás seguro de guardar la configuración?
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer>
                            </v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDialogSave" :disabled="disBoton">
                                Cancelar
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="saveConfiguration" :disabled="disBoton">
                                Guardar
                            </v-btn>
                            <v-spacer>
                            </v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template>
            <v-container fluid>
                <v-switch v-model="switchIncidencia" @change="cambioSwitch" color="primary" label="Permitir agregar reporte de incidencia en la evaluación">
                </v-switch>
            </v-container>
        </template>
        <template>
            <v-snackbar
                v-model = "alert"
                :timeout="tiempo_muestra"
                :value="true"
                :color = "color_alerta"
                absolute
                right
                rounded="pill"
            >
            <v-icon
                class="mr-3"
            >
                {{icono_alerta}}
            </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
    import service from "@/core/Service.js";

    export default {
        metaInfo:{
            title: "Configuración"
        },

        data: () => ({
            url_ruta: "configuration",
            dialogSave: false,
            disBoton: false,
            tiempo_muestra: 1500,
            switchIncidencia: null,
            compania: JSON.parse(localStorage.getItem("company")),
            alert: false,
            texto_alerta: "",
            icono_alerta: "",
            color_alerta: "",
            loader: true,
            loading: false,
            detalle: [],
            editedItem: {
                id: 0,
                incidence: null,
                company_id: 0
            },
            defaultItem: {
                id: 0,
                incidence: null,
                company_id: 0
            }
        }),

        created(){
            this.consultarInformacion();
        },

        watch: {
            dialogSave(val){
                val || this.closeDialogSave();
            }
        },

        methods: {
            consultarInformacion(){
                this.loader = true;
                service.buscarDatos(this.url_ruta, this.compania.id).then((response) => {
                    this.loader = false;
                    if(response.data.response_flag === 1){
                        if(response.data.response[0].company_id === this.compania.id){
                            this.editedItem = Object.assign({}, response.data.response[0]);
                            this.inicializarSwitch();
                        }
                        else{
                            this.editedItem = Object.assign({}, this.defaultItem);
                            this.inicializarSwitch();
                        }
                    }
                })
                .catch((error) => {
                    console.log("Problema al consultar la configuración: " + error);
                });
            },

            cambioSwitch(){
                if(this.switchIncidencia == true){
                    this.editedItem.incidence = 0;
                }
                else{
                    this.editedItem.incidence = 1;
                }
            },

            inicializarSwitch(){
                if(this.editedItem.incidence === 0){
                    this.switchIncidencia = true;
                }
                else{
                    this.switchIncidencia = false;
                }
            },

            closeDialogSave(){
                this.dialogSave = false;
            },

            saveConfiguration(){
                this.loading = true;
                this.disBoton = true;
                if(this.editedItem.id === 0){
                    this.editedItem.company_id = this.compania.id;
                    service.ingresarDatos(this.url_ruta, this.editedItem).then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarInformacion();
                            this.loading = false;
                            this.disBoton = false;
                            this.closeDialogSave();
                            this.texto_alerta= "Se ha guardado con éxito la configuración"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else{
                            this.consultarInformacion();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al guardar la configuración"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                    .catch((error) => {
                        console.log("Problema al consultar la configuración" + error);
                    });
                }
                else{
                    service.modificarDatos(this.url_ruta, this.editedItem, this.editedItem.id).then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarInformacion();
                            this.loading = false;
                            this.disBoton = false;
                            this.closeDialogSave();
                            this.texto_alerta= "Se ha modificado con éxito la configuración"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else{
                            this.consultarInformacion();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar la configuración"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                    .catch((error) => {
                        console.log("Problema al consultar la configuración" + error);
                    });
                }
            }
        }
    }
</script>