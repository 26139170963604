<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Clientes</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref="form" lazy-validation>
                        <v-dialog v-model="dialog" width="900px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card :loading="loading">
                                <template slot="progress">
                                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                                </template>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="8" md="8">
                                                <v-text-field v-model="editedItem.name_or_business_name" @input="editedItem.name_or_business_name = editedItem.name_or_business_name.toUpperCase()" label="Nombre / Razon Social"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.rfc" @input="editedItem.rfc = editedItem.rfc.toUpperCase()" label="RFC"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="2" md="2">
                                                <v-text-field v-model="editedItem.postal_code" @input="editedItem.postal_code = editedItem.postal_code.toUpperCase()" label="C.P"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-autocomplete :items="estados" item-text="state"
                                                    item-value="id" v-model="editedItem.state_id"
                                                    label="Estado" :rules=rules_validar @change="filtrarMunicipios" >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3">
                                                <v-autocomplete :items="municipios" item-text="city"
                                                    item-value="id" v-model="editedItem.city_id"
                                                    label="Municipio" :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.suburb" @input="editedItem.suburb = editedItem.suburb.toUpperCase()" label="Colonia"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.street" @input="editedItem.street = editedItem.street.toUpperCase()" label="Calle"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.outdoor_number" @input="editedItem.outdoor_number = editedItem.outdoor_number.toUpperCase()" label="Numero"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.interior_number" @input="editedItem.interior_number = editedItem.interior_number.toUpperCase()" label="Numero Interior"
                                                    >
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.email" label="Correo Electronico"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="editedItem.phone_number" label="Numero de Telefono"
                                                    :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                           
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title class="text-h5">¿Estas seguro de eliminar?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon color="closeSesion" small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarClientes">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <template>
            <v-snackbar
                v-model = "alert"
                :timeout="tiempo_muestra"
                :value="true"
                :color = "color_alerta"
                absolute
                right
                rounded="pill"
            >
            <v-icon
                class="mr-3"
            >
                {{icono_alerta}}
            </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>



<script>

import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Clientes'
    },
    data: () => ({
        tiempo_muestra:1500,
        url_ruta:"client",
        alert: false,
        texto_alerta:"",
        icono_alerta: "",
        color_alerta: "",
        dialog: false,
        disBoton: false,
        loading: false,
        dialogDelete: false,
        search: "",
        loader: true,
        encabezados: [
            {
                text: 'Clave',
                value: 'id',
            },
            {
                text: 'Nombre/ Razon Social',
                value: 'name_or_business_name',
            },
            {
                text: 'RFC',
                value: 'rfc',
            },
            {
                text: 'Correo',
                value: 'email',
            },
            {
                text: 'Telefono',
                value: 'phone_number',
            },
            {
                text: 'Estado',
                value: 'state',
            },
            {
                text: 'Ciudad',
                value: 'city',
            },
           
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        detalles: [],
        editedIndex: -1,
        editedItem: {
            id: 0,
            name_or_business_name: '',
            rfc: '',
            city_id: '',
            state_id: '',
            suburb: '',
            street: '',
            outdoor_number: '',
            interior_number: '',
            postal_code: '',
            email: '',
            phone_number: '',
        },
        defaultItem: {
            id: 0,
            name_or_business_name: '',
            rfc: '',
            city_id: '',
            state_id: '',
            suburb: '',
            street: '',
            outdoor_number: '',
            interior_number: '',
            postal_code: '',
            email: '',
            phone_number: '',
        },
        rules_validar: [(v) => !!v || "Campo requerido"],
        estados:[],
        municipios_servidor:[],
        municipios:[]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Modificar Cliente'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.consultarClientes()
        this.consultarEstados()
        this.consultarMunicipios()
    },

    methods: {
        consultarClientes() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Clientes");
                });
        },


        consultarEstados() {
            this.loader = true;
            service
                .consultarEstados()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.estados = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Clientes");
                });
        },

        consultarMunicipios() {
            this.loader = true;
            service
                .consultarMunicipios()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.municipios_servidor = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Clientes");
                });
        },

        editItem(item) {
            this.municipios = [];
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
            this.filtrarMunicipios();
            this.$refs.form.resetValidation();
        },

        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.disBoton = true;
            this.loading = true;
            service
                .eliminarDatos(this.url_ruta, this.editedItem , this.editedItem.id)
                .then((response) => {
                    if(response.data.response_flag === 1){
                        this.consultarClientes();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Se ha eliminado con éxito el cliente"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if(response.data.response_flag !== 1 || response.data === null){
                        this.consultarClientes();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta= "Ocurrió un error al eliminar el cliente"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar clientes");
                });
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            });
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.disBoton = true;
                if (this.editedIndex > -1) {
                    service
                        .modificarDatos(this.url_ruta, this.editedItem,this.editedItem.id)
                        .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarClientes();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha modificado con éxito el cliente"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarClientes();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al modificar el cliente"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                    .catch((error) => {
                        console.log("Problema al consultar clientes" + error);
                    })
                } else {
                    service
                        .ingresarDatos(this.url_ruta,this.editedItem)
                        .then((response) => {
                        if(response.data.response_flag === 1){
                            this.consultarClientes();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Se ha guardado con éxito el cliente"
                            this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                            this.color_alerta = "success";
                            this.alert = true;
                        }
                        else if(response.data.response_flag !== 1 || response.data === null){
                            this.consultarClientes();
                            this.loading = false;
                            this.disBoton = false;
                            this.close();
                            this.texto_alerta= "Ocurrió un error al guardar el cliente"
                            this.icono_alerta = "mdi-close-circle";
                            this.color_alerta = "red darken-2";
                            this.alert = true;
                        }
                    })
                        .catch(() => {
                            console.log("Problema al consultar clientes");
                        });
                }
            }
        },


        filtrarMunicipios(){
            this.municipios = this.municipios_servidor.filter(municipio => municipio.state_id == this.editedItem.state_id); 
        },
    },
}
</script>