<template>
  <v-app id="inspire">
    <v-navigation-drawer absolute temporary v-model="drawer" app v-bind:width="325">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="https://randomuser.me/api/portraits/men/20.jpg">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ usuario_actual.email }}</v-list-item-title>
            <v-list-item-subtitle>josuesorcia159@gmail.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-text-field label="Buscar" v-model="buscador" prepend-icon="mdi-feature-search" @input="buscarDetalles">
      </v-text-field>
      <v-list-group :value="false" v-for="item in detalles" :key="item.title">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon color="primary" size="20">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-sm-body-2 font-weight-bold">{{item.nombre}}</v-list-item-title>
        </template>
        <v-list-item v-for="modulo in item.modulos" :to="modulo.action" :key="modulo.title">
          <v-list-item-icon>
            <v-icon color="primary" size="20">{{ modulo.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-sm-body-2">{{ modulo.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- <v-list-item to="/Configuracion">
        <v-list-item-icon>
          <v-icon color="primary" size="20">
            settings
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-sm-body-2">
            Configuración
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">Control de Producción</v-toolbar-title>
      <v-spacer>
      </v-spacer>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="mb-2 white--text">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="n in opciones" :key="n.key" @click="seleccionarOpcion(n.key)">
            <v-list-item-icon>
              <v-icon color="primary">
                {{ n.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-sm-body-2 font-weight-bold">
                {{ n.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog v-model="dialogCerrarSesion" max-width="500px" persistent>
      <v-card :loading="loading">
        <template slot="progress">
          <v-progress-linear color="primary" height="7" indeterminate>
          </v-progress-linear>
        </template>
        <v-card-title class="text-h5">
          ¿Estas seguro de cerrar sesión?
        </v-card-title>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn color="blue darken-1" text @click="cerrarVentanaCerrarSesion" :disabled="disBoton">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="cerrarSesion" :disabled="disBoton">
            Cerrar Sesión
          </v-btn>
          <v-spacer>
          </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view>

      </router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title: 'Inicio'
  },
  data: () => ({
    disBoton: false,
    drawer: null,
    loading: false,
    usuario_actual: {
      name: "Josue",
      email: "Martin"
    },
    dialog: false,
    buscador: "",
    dialogCerrarSesion: false,
    opciones: [
      { title: 'Cerrar Sesión', icon: 'mdi-exit-to-app', key: 1 }
    ],
    detalles: [],
    detalles_original: [
      {
        nombre: 'Administración',
        icon: 'mdi-account-group',
        modulos: [
          { title: 'Perfiles', icon: 'mdi-account', action: "/perfil" },
          { title: 'Usuarios', icon: 'mdi-account-convert', action: "/usuario" },
          { title: 'Departamento', icon: 'mdi-office-building', action: "/departamento" },
          { title: 'Puesto', icon: 'mdi-nature-people', action: "/puesto" },
          { title: 'Turnos', icon: 'mdi-account-clock', action: "/turno" },
          { title: 'Clientes', icon: 'mdi-nature-people', action: "/cliente" },
          { title: 'Empleados', icon: 'mdi-account', action: "/empleados" },
        ]
      },
      {
        nombre: 'Producción',
        icon: 'mdi-cog-sync-outline',
        modulos: [
          { title: 'Maquinas', icon: 'mdi-car-multiple', action: "/maquina" },
          { title: 'Categoria Maquina', icon: 'mdi-state-machine', action: "/maquina_categoria" },
          { title: 'Evaluación', icon: 'mdi-notebook-check', action: "/evaluacion_maquina" },
          { title: 'Calendario de Evaluación', icon: 'event', action: '/Calendario' },
          { title: 'Metodologia de Calidad', icon: 'mdi-file-document-outline', action: "/documento" },
          { title: 'Piezas', icon: 'mdi-car-door', action: "/pieza" },
          { title: 'Categoria de Piezas', icon: 'mdi-car-info', action: "/categoria_pieza" },
          { title: 'Unidad de Medida', icon: 'mdi-move-resize', action: "/unidad_medida" },
          { title: 'Indicadores', icon: 'mdi-playlist-check', action: "/indicador" },
          { title: 'Areas de Trabajo', icon: 'mdi-office-building-outline', action: "/area_trabajo" },
          { title: 'Lineas de Trabjo', icon: 'mdi-arrow-down-box', action: "/linea_produccion" },
          { title: 'Procesos', icon: 'mdi-engine', action: '/procesos' }
        ]
      },
      {
        nombre: 'Ordenes de Producción',
        icon: 'mdi-cogs',
        modulos: [
          { title: 'Ordenes de Planeación', icon: 'mdi-clock-check-outline', action: "/ordenes_produccion" },
          { title: 'Evaluación', icon: 'mdi-book-check-outline', action: "/evaluacion_orden_produccion" },
          { title: 'Productividad', icon: 'mdi-chart-box', action: "/informacion_orden_produccion" },
          { title: 'Evaluacion de Maquinas', icon: 'mdi-chart-box', action: "/evaluacion_maquina" },
        ]
      },
    ],
  }),
  watch: {
    dialogCerrarSesion(val) {
      val || this.dialogCerrarSesion();
    }
  },
  created() {
    this.inicializar();
  },
  methods: {
    inicializar() {
      this.usuario_actual = JSON.parse(localStorage.getItem('usuario'));
      if (localStorage.getItem("usuario") === null) {
        this.$router.push("/");
      } else {
        this.usuario_actual = JSON.parse(localStorage.getItem('usuario'));
      }

      this.buscarDetalles()
    },

    seleccionarOpcion(accionkey) {
      switch (accionkey) {
        case 1:
          this.ventanaCerrarSesion();
          break;
      }
    },

    cerrarSesion() {
      localStorage.removeItem("usuario");
      localStorage.removeItem("company");
      this.$router.push("/");
    },

    cerrarVentanaCerrarSesion() {
      this.dialogCerrarSesion = false;
    },

    ventanaCerrarSesion() {
      this.dialogCerrarSesion = true;
    },

    buscarDetalles() {
      let detalles_encontrados = new Array();
      //Recorremos los detalles encabezado
      this.detalles_original.forEach(modulo => {
        //Recorremos los detalles
        modulo.modulos.forEach(modulo_detalle => {
          if (modulo_detalle.title.toLowerCase().includes(this.buscador.toLowerCase())) {
            let modulo_encabezado_encontrado = false;
            //Revisamos si ya esta el modulo encabezado
            detalles_encontrados.forEach(modulo_encabezado_buscado => {
              if (modulo_encabezado_buscado.nombre === modulo.nombre) {
                modulo_encabezado_encontrado = true;
                modulo_encabezado_buscado.modulos.push(modulo_detalle);
              }
            });
            if(!modulo_encabezado_encontrado){
              let modulo_encabezado_nuevo =  Object.assign({}, modulo);
              modulo_encabezado_nuevo.modulos = [];
              modulo_encabezado_nuevo.modulos.push(modulo_detalle);
              detalles_encontrados.push(modulo_encabezado_nuevo);
            }
          }
        });
      });
      this.detalles = detalles_encontrados;
    }
  }
}
</script>