<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table">
        </v-skeleton-loader>
    </div>
    <div v-else>
        <v-app id="inspire">
            <v-app-bar app color="primary">
                <v-spacer>
                </v-spacer>
                <v-spacer>
                </v-spacer>
                <v-toolbar-title class="white--text">
                    COMPANÍAS DISPONIBLES
                </v-toolbar-title>
                <v-spacer>
                </v-spacer>
                <v-form ref="form" lazy-validation>
                    <v-dialog v-model="dialog" width="900px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="textWhite" text class="mb-2 mr-4" v-bind="attrs" v-on="on">
                                <v-icon left>
                                    mdi-plus
                                </v-icon>
                                Agregar
                            </v-btn>
                        </template>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.company" @input="editedItem.company = editedItem.company.toUpperCase()" label="Compañía"
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.bussines_name" @input="editedItem.bussines_name = editedItem.bussines_name.toUpperCase()" label="Nombre de Negocio"
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.short_name" @input="editedItem.short_name = editedItem.short_name.toUpperCase()" label="Nombre Corto"
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2">
                                            <v-text-field v-model="editedItem.rfc" @input="editedItem.rfc = editedItem.rfc.toUpperCase()" label="RFC" :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-text-field v-model="editedItem.location" @input="editedItem.location = editedItem.location.toUpperCase()" label="Calle"
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2">
                                            <v-text-field v-model="editedItem.outdoor_number" @input="editedItem.outdoor_number = editedItem.outdoor_number.toUpperCase()" label="No. Exterior"
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2">
                                            <v-text-field v-model="editedItem.internal_number" @input="editedItem.internal_number = editedItem.internal_number.toUpperCase()" label="No. Interior">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-text-field v-model="editedItem.suburb" @input="editedItem.suburb = editedItem.suburb.toUpperCase()" label="Colonia"
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="1" md="1">
                                            <v-text-field v-model="editedItem.postal_code" label="C.P."
                                                :rules=rules_validar>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-autocomplete :items="estados" item-text="state" item-value="id"
                                                v-model="editedItem.state_id" label="Estado" :rules=rules_validar
                                                @change="filtrarMunicipios">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-autocomplete :items="municipios" item-text="city" item-value="id"
                                                v-model="editedItem.city_id" label="Municipio" :rules=rules_validar>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-file-input v-model="archivo" :label="_nombreArchivo"
                                                @change="mostrarImagen" :rules=rules_validar>
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-img :src="url">
                                            </v-img>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                    Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="insertarCompania" :disabled="disBoton">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-form>
                <v-btn color="closeSesion" @click="ventanaCerrarSesion" class="mb-2 white--text">
                    Cerrar Sesión
                </v-btn>
            </v-app-bar>
            <v-main>
                <v-slide-group v-model="carrusel" center-active class="pa-5" show-arrows @click:next="avanzarCarrusel" @click:prev="retrocederCarrusel">
                    <v-slide-item v-for="detalle in detalles" :key="detalle.id">
                        <v-card class="ma-5 my-12" elevation="3" height="500" width="310">
                            <v-img height="200" :src="detalle.logo">
                            </v-img>
                            <v-card-title>
                                {{detalle.bussines_name}}
                            </v-card-title>
                            <v-card-text>
                                <div class="black--text">
                                    RFC: {{detalle.rfc}}
                                </div>
                                <div class="black--text">
                                    Dirección: {{detalle.location + " " + detalle.outdoor_number + " " +
                                    detalle.internal_number + " " + detalle.suburb + " " + detalle.city + " " + detalle.state}}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4">
                            </v-divider>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="ventanaIrAMenuInicio(detalle)">
                                    SELECCIONAR
                                </v-btn>
                                <v-spacer>
                                </v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
                <v-dialog v-model="dialogMenuInicio" max-width="600px" persistent>
                    <v-card :loading="loading">
                        <template slot="progress">
                            <v-progress-linear color="primary" height="7" indeterminate>
                            </v-progress-linear>
                        </template>
                        <v-card-title class="text-h5">
                            ¿Estas seguro de seleccionar esta compañía?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer>
                            </v-spacer>
                            <v-btn color="blue darken-1" text @click="cerrarVentanaIrAMenuInicio" :disabled="disBoton">
                                Cancelar
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="irAMenuInicio" :disabled="disBoton">
                                Avanzar
                            </v-btn>
                            <v-spacer>
                            </v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogCerrarSesion" max-width="500px" persistent>
                    <v-card :loading="loading">
                        <template slot="progress">
                            <v-progress-linear color="primary" height="7" indeterminate>
                            </v-progress-linear>
                        </template>
                        <v-card-title class="text-h5">
                            ¿Estas seguro de cerrar sesión?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer>
                            </v-spacer>
                            <v-btn color="blue darken-1" text @click="cerrarVentanaCerrarSesion" :disabled="disBoton">
                                Cancelar
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="cerrarSesion" :disabled="disBoton">
                                Cerrar Sesión
                            </v-btn>
                            <v-spacer>
                            </v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-main>
        </v-app>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Compañías'
    },
    data: () => ({
        alert: false,
        carrusel: 0,
        texto_alerta: "",
        icono_alerta: "",
        color_alerta: "",
        loader: true,
        dialog: false,
        archivo: null,
        url: null,
        logo: null,
        dialogCerrarSesion: false,
        dialogMenuInicio: false,
        municipio_estado: "",
        encabezados: [
            {
                text: 'Clave',
                value: 'id',
            },
            {
                text: 'Compañía',
                value: 'company',
            },
            {
                text: 'Nombre',
                value: 'short_name'
            },
            {
                text: 'RFC',
                value: 'rfc',
            },
            {
                text: 'Estado',
                value: 'state',
            },
            {
                text: 'Ciudad',
                value: 'city',
            },
            {
                text: 'Logo',
                value: 'logo'
            },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        compania: {},
        detalles: [],
        rules_validar: [(v) => !!v || "Campo requerido"],
        editedItem: {
            id: 0,
            company: '',
            bussines_name: '',
            short_name: '',
            location: '',
            rfc: '',
            city_id: '',
            state_id: '',
            suburb: '',
            outdoor_number: '',
            internal_number: '',
            logo: '',
            file_name: ''
        },
        defaultItem: {
            id: 0,
            company: '',
            bussines_name: '',
            short_name: '',
            location: '',
            rfc: '',
            city_id: '',
            state_id: '',
            suburb: '',
            outdoor_number: '',
            internal_number: '',
            logo: '',
            file_name: ''
        },
        estados: [],
        municipios_servidor: [],
        municipios: []
    }),

    computed: {
        formTitle() {
            return 'Nueva Compañía';
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogCerrarSesion(val) {
            val || this.cerrarVentanaCerrarSesion();
        },
        dialogMenuInicio(val) {
            val || this.cerrarVentanaIrAMenuInicio();
        }
    },

    created() {
        this.consultarCompanias();
        this.consultarMunicipios();
        this.consultarEstados();
    },

    methods: {
        consultarCompanias() {
            this.loader = true;
            service.consultarCompanias().then((response) => {
                this.loader = false;
                if (response.data.response_flag === 1) {
                    this.detalles = response.data.response;
                }
            })
                .catch((error) => {
                    this.loader = false;
                    console.log("Problema al consultar compañías: " + error);
                })
        },

        async mostrarImagen() {
            if (this.archivo !== '') {
                this._nombreArchivo = ""
                this.url = URL.createObjectURL(this.archivo);
                let _var = this.convertirImageToBase64(this.archivo);
                this.editedItem.logo = await _var;
                this.editedItem.file_name = this.archivo.name;
            }
            else {
                this.url = null
            }
        },

        convertirBase64ToImage(base64, nombreArchivo) {
            this.url = base64;
            this._nombreArchivo = nombreArchivo;
        },

        convertirImageToBase64(file) {
            return new Promise(function (resolve, reject) {
                let reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },

        consultarEstados() {
            this.loader = true;
            service
                .consultarEstados()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.estados = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Estados");
                });
        },

        consultarMunicipios() {
            this.loader = true;
            service
                .consultarMunicipios()
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.municipios_servidor = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar Municipios");
                });
        },

        filtrarMunicipios() {
            this.municipios = this.municipios_servidor.filter(municipio => municipio.state_id == this.editedItem.state_id);
        },

        insertarCompania() {
            if (this.$refs.form.validate()) {
                this.loader = true;
                this.disBoton = true;
                service.ingresarCompania(this.editedItem).then((response) => {
                    if (response.data.response_flag === 1) {
                        this.consultarCompanias();
                        this.loading = false;
                        this.disBoton = false;
                        this.close();
                        this.texto_alerta = "Se ha modificado con éxito la compañía"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                })
                    .catch((error) => {
                        console.log("Problema al consultar compañías" + error);
                    });
            }
        },

        ventanaIrAMenuInicio(item) {
            this.editedItem = Object.assign({}, item);
            console.log(this.editedItem);
            this.dialogMenuInicio = true;
        },

        ventanaCerrarSesion() {
            this.dialogCerrarSesion = true;
        },

        cerrarVentanaCerrarSesion() {
            this.dialogCerrarSesion = false;
        },

        cerrarVentanaIrAMenuInicio() {
            this.dialogMenuInicio = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.url = null;
                this.archivo = null;
                this._nombreArchivo = "";
            });
            this.$refs.form.resetValidation();
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.url = null;
                this.archivo = null;
                this._nombreArchivo = "";
            });
            this.$refs.form.resetValidation();
        },

        irAMenuInicio() {
            localStorage.setItem("company", JSON.stringify(this.editedItem));
            this.$router.push("/Inicio");
        },

        cerrarSesion() {
            localStorage.removeItem("usuario");
            localStorage.removeItem("company");
            this.$router.push("/");
        },

        avanzarCarrusel(){
            this.carrusel++;
            this.carrusel == this.detalles.length ? (this.carrusel = 0) : "";
        },

        retrocederCarrusel(){
            this.carrusel--;
        }
    },
}
</script>