<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" :items="detalles" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Indicadores</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-form ref="form" lazy-validation>
                        <v-dialog v-model="dialog" max-width="700px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card :loading="loading">
                                <template slot="progress">
                                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                                </template>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.activity"
                                                    @input="editedItem.activity = editedItem.activity.toUpperCase()"
                                                    label="Actividad" :rules=rules_validar>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.code"
                                                    @input="editedItem.code = editedItem.code.toUpperCase()"
                                                    label="Clave" :rules=rules_validar></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-autocomplete :items="categorias" item-text="categoria"
                                                    item-value="id" @change="ocultarRangos"
                                                    v-model="editedItem.activity_category_id" label="Categoria"
                                                    :rules=rules_validar>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" :hidden="ocultar_rangos">
                                                <v-slider v-model="editedItem.minimum_percentage" color="green"
                                                    label="Minimo" thumb-color="green" thumb-label="always"
                                                    :rules=rules_rangos></v-slider>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" :hidden="ocultar_rangos">
                                                <v-slider v-model="editedItem.maximum_percentage" color="red"
                                                    label="Maximo" thumb-color="red" thumb-label="always"
                                                    :rules=rules_rangos></v-slider>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" :hidden="ocultar_rangos">
                                                <v-slider v-model="editedItem.minimum_percentage_evaluation"
                                                    color="blue" label="Mínimo Óptimo" thumb-color="blue"
                                                    thumb-label="always" :error-messages="error_messages" :rules=rules_rangos>
                                                </v-slider>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" :hidden="ocultar_rangos">
                                                <v-slider v-model="editedItem.maximum_percentage_evaluation"
                                                    color="blue" label="Máximo Óptimo" thumb-color="blue"
                                                    thumb-label="always" :error-messages="error_messages" :rules=rules_rangos>
                                                </v-slider>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.observations"
                                                    @input="editedItem.observations = editedItem.observations.toUpperCase()"
                                                    label="Observaciones">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close" :disabled="disBoton">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save" :disabled="disBoton">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <template slot="progress">
                                <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                            </template>
                            <v-card-title class="text-h5">¿Estas seguro de eliminar?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="disBoton">Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="disBoton">OK
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="buttonEdit" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon color="closeSesion" small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarActividades()">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <template>
            <v-snackbar v-model="alert" :timeout="tiempo_muestra" :value="true" :color="color_alerta" absolute right
                rounded="pill">
                <v-icon class="mr-3">
                    {{icono_alerta}}
                </v-icon>
                {{texto_alerta}}
            </v-snackbar>
        </template>
    </div>
</template>



<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Actividades'
    },
    data: () => ({
        url_ruta: "activity",
        alert: false,
        texto_alerta: "",
        icono_alerta: "",
        error_messages: [],
        minimo: 0,
        maximo: 0,
        color_alerta: "",
        dialog: false,
        dialogDelete: false,
        valores_rangos: [],
        search: "",
        loader: true,
        loading: false,
        ocultar_rangos: true,
        disBoton: false,
        tiempo_muestra: 1500,
        min: 50,
        max: 90,
        slider: 40,
        encabezados: [
            { text: 'Clave', value: 'code' },
            { text: 'Indicador', value: 'activity' },
            { text: 'Categoria', value: 'activity_category' },
            { text: 'Observaciones', value: 'observations' },
            { text: 'Acciones', value: 'actions', sortable: false },
        ],
        categorias: [
            {
                categoria: 'RANGOS',
                id: 1,
            },
            {
                categoria: 'VERDADERO O FALSO',
                id: 2,
            },
            {
                categoria: 'NUMÉRICO',
                id: 3,
            },
            {
                categoria: 'TEXTO LIBRE',
                id: 4,
            },
        ],
        detalles: [],
        editedIndex: -1,
        editedItem: {
            code: '',
            activity: '',
            activity_category: '',
            observations: '',
            activity_category_id: 0,
            minimum_percentage: 0,
            maximum_percentage: 0,
            minimum_percentage_evaluation: 0,
            maximum_percentage_evaluation: 0
        },
        defaultItem: {
            code: '',
            activity: '',
            activity_category: '',
            observations: '',
            activity_category_id: 0,
            minimum_percentage: 0,
            maximum_percentage: 0,
            minimum_percentage_evaluation: 0,
            maximum_percentage_evaluation: 0
        },
        rules_validar: [(v) => !!v || "Campo requerido"],
        rules_rangos: [(v) => !!v || "Campo requerido"]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Indicador' : 'Modificar Indicador'
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        }
    },

    created() {
        this.consultarActividades()
    },

    methods: {
        consultarActividades() {
            this.loader = true;
            service
                .consultarDatos(this.url_ruta)
                .then((response) => {
                    this.loader = false;
                    if (response.data.response_flag == 1) {
                        this.detalles = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar actividades");
                });
        },

        editItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
            this.ocultarRangos();
        },
        deleteItem(item) {
            this.editedIndex = this.detalles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.loading = true;
            this.disBoton = true;
            service
                .eliminarDatos(this.url_ruta, this.editedItem, this.editedItem.id)
                .then((response) => {
                    if (response.data.response_flag === 1) {
                        this.consultarActividades();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta = "Se ha eliminado con éxito el indicador"
                        this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                        this.color_alerta = "success";
                        this.alert = true;
                    }
                    else if (response.data.response_flag !== 1 || response.data === null) {
                        this.consultarActividades();
                        this.loading = false;
                        this.disBoton = false;
                        this.closeDelete();
                        this.texto_alerta = "Ocurrió un error al eliminar el indicador"
                        this.icono_alerta = "mdi-close-circle";
                        this.color_alerta = "red darken-2";
                        this.alert = true;
                    }
                })
                .catch(() => {
                    console.log("Problema al consultar los indicadores");
                });
        },

        ocultarRangos() {
            if (this.editedItem.activity_category_id === 1) {
                this.rules_rangos = [(v) => !!v || "Campo requerido"];
                this.ocultar_rangos = false;
            } else {
                this.rules_rangos = [];
                this.ocultar_rangos = true;
            }
        },

        async evaluarRangos() {
            this.minimo = this.editedItem.minimum_percentage;
            this.maximo = this.editedItem.maximum_percentage;
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.$refs.form.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedItem.minimum_percentage_evaluation >= this.editedItem.minimum_percentage && 
                this.editedItem.maximum_percentage_evaluation <= this.editedItem.maximum_percentage &&
                this.editedItem.minimum_percentage_evaluation <= this.editedItem.maximum_percentage &&
                this.editedItem.maximum_percentage_evaluation >= this.editedItem.minimum_percentage &&
                this.editedItem.minimum_percentage_evaluation < this.editedItem.maximum_percentage_evaluation) {
                this.error_messages = [];
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    this.disBoton = true;
                    if (this.editedIndex > -1) {
                        service
                            .modificarDatos(this.url_ruta, this.editedItem, this.editedItem.id)
                            .then((response) => {
                                if (response.data.response_flag === 1) {
                                    this.consultarActividades();
                                    this.loading = false;
                                    this.disBoton = false;
                                    this.close();
                                    this.texto_alerta = "Se ha modificado con éxito el indicador"
                                    this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                                    this.color_alerta = "success";
                                    this.alert = true;
                                }
                                else if (response.data.response_flag !== 1 || response.data === null) {
                                    this.consultarActividades();
                                    this.loading = false;
                                    this.disBoton = false;
                                    this.close();
                                    this.texto_alerta = "Ocurrió un error al modificar el indicador"
                                    this.icono_alerta = "mdi-close-circle";
                                    this.color_alerta = "red darken-2";
                                    this.alert = true;
                                }
                            })
                            .catch(() => {
                                console.log("Problema al consultar los indicadores");
                            });
                    } else {
                        service
                            .ingresarDatos(this.url_ruta, this.editedItem)
                            .then((response) => {
                                if (response.data.response_flag === 1) {
                                    this.consultarActividades();
                                    this.loading = false;
                                    this.disBoton = false;
                                    this.close();
                                    this.texto_alerta = "Se ha guardado con éxito el indicador"
                                    this.icono_alerta = "mdi-checkbox-marked-circle-outline";
                                    this.color_alerta = "success";
                                    this.alert = true;
                                }
                                else if (response.data.response_flag !== 1 || response.data === null) {
                                    this.consultarActividades();
                                    this.loading = false;
                                    this.disBoton = false;
                                    this.close();
                                    this.texto_alerta = "Ocurrió un error al modificar el indicador"
                                    this.icono_alerta = "mdi-close-circle";
                                    this.color_alerta = "red darken-2";
                                    this.alert = true;
                                }
                            })
                            .catch(() => {
                                console.log("Problema al consultar los indicadores");
                            });
                    }
                }
            }
            else {
                this.error_messages.push("Los rangos están mal configurados");
            }
        }
    },
}
</script>