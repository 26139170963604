<template>
    <div v-if="loader">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </div>
    <div v-else>
        <v-data-table :headers="encabezados" height="675" :items-per-page="-1" fixed-header :items="detalles"
            sort-by="date_evaluation" :sort-desc="valor" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Evaluación de Maquina</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" width="700px" max-width="900px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                <v-icon left>
                                    mdi-plus
                                </v-icon>
                                Nuevo
                            </v-btn>
                        </template>
                        <v-card>
                            <v-window v-model="step">
                                <v-window-item :value="1">
                                    <v-form ref="form_evaluation" lazy-validation>
                                        <v-card-title>
                                            <span class="text-h5">Generar Evalución</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="8" md="8">
                                                        <v-autocomplete :items="maquinas" item-text="machine"
                                                            item-value="id" v-model="editedItem.machine_id"
                                                            label="Maquina" :rules=rules_validar
                                                            @change="consultarDocumentos(editedItem.machine_id)">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="4" md="4">
                                                        <v-autocomplete :items="documentos" item-text="document"
                                                            item-value="id" v-model="editedItem.document_id"
                                                            label="Metodología" :rules=rules_validar>
                                                        </v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-autocomplete :items="clientes"
                                                            item-text="name_or_business_name" item-value="id"
                                                            v-model="editedItem.client_id" label="Cliente">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-autocomplete :items="piezas" item-text="piece"
                                                            item-value="id" v-model="editedItem.piece_id" label="Pieza">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-autocomplete :items="turnos" item-text="turn" item-value="id"
                                                            v-model="editedItem.turn_id" label="Turno">
                                                        </v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="dialog = false">
                                                Cancelar
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="consultarActividadesAsigandas">
                                                Generar Evaluación
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <div v-if="loader_evaluation">
                                        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                                    </div>
                                    <div v-else>
                                        <v-card-title>
                                            <v-chip class="ma-2" color="success" outlined>
                                                <v-icon left>
                                                    mdi-server-plus
                                                </v-icon>
                                                ( {{ indicador_index }} / {{ indicadores_detalles.length }} )
                                            </v-chip>
                                            <span class="text-h5">Evaluación de Indicadores</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="2" md="2">
                                                        <v-text-field v-model="indicador_seleccionado.code"
                                                            @input="editedItem.code = editedItem.code.toUpperCase()"
                                                            label="Clave" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="10" md="10">
                                                        <v-text-field v-model="indicador_seleccionado.activity"
                                                            @input="editedItem.activity = editedItem.activity.toUpperCase()"
                                                            label="Actividad" readonly>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="6">
                                                        <v-autocomplete :items="categorias" item-text="categoria"
                                                            item-value="id" @change="ocultarRangos"
                                                            v-model="indicador_seleccionado.activity_category_id"
                                                            label="Categoria" readonly>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="4" md="4" :hidden="!ocultar_rangos">
                                                        <v-checkbox v-model="indicador_seleccionado.califacation"
                                                            label="Se realizo">
                                                        </v-checkbox>
                                                    </v-col>
                                                    <v-col cols="12" sm="7" md="7" :hidden="ocultar_rangos">
                                                        <v-slider
                                                            v-model="indicador_seleccionado.minimum_percentage_evaluation"
                                                            color="green" label="Minimo" thumb-color="green"
                                                            thumb-label="always" readonly></v-slider>
                                                    </v-col>
                                                    <v-col cols="12" sm="7" md="7" :hidden="ocultar_rangos">
                                                        <v-slider
                                                            v-model="indicador_seleccionado.maximum_percentage_evaluation"
                                                            color="green" label="Maximo" thumb-color="green"
                                                            thumb-label="always" readonly></v-slider>
                                                    </v-col>

                                                    <v-col cols="12" sm="12" md="12" :hidden="ocultar_rangos">
                                                        <v-slider v-model="indicador_seleccionado.califacation"
                                                            :color="obtenerColorEvaluacion" label="Rango Evaluado"
                                                            :thumb-color="obtenerColorEvaluacion" thumb-label="always">
                                                        </v-slider>
                                                    </v-col>
                                                </v-row>
                                                <v-card color="#fff176" v-if="obtenerIncidenciaEvaluacion">
                                                    <v-card-title>
                                                        <v-chip class="ma-2" color="black" outlined>
                                                            <v-icon left>
                                                                mdi-server-remove
                                                            </v-icon>
                                                            Registro de Incidencia
                                                        </v-chip>
                                                    </v-card-title>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-autocomplete color="primary"
                                                            v-model="indicador_seleccionado.incidence_type"
                                                            :items="tipos_incidencias" item-text="incidence_type"
                                                            return-object item-value="id" label="Tipo de Incidencia">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" :hidden="evidenciaVisible">
                                                        <v-file-input label="Agregar imagenes de la incidencia"
                                                            accept="image/png, image/jpeg, image/bmp" v-model="archivo"
                                                            multiple small-chips prepend-icon="mdi-camera"
                                                            @change="mostrarImagen" @click:clear="hideComponente">
                                                            <template v-slot:selection="{ text }">
                                                                <v-chip small text-color="white" color="#000000">
                                                                    {{ text }}
                                                                </v-chip>
                                                            </template>
                                                        </v-file-input>
                                                    </v-col>
                                                    <v-row>
                                                        <v-col cols="4" sm="4" md="4" :style="ocultarComponente"
                                                            v-for="image in url" :key="image.id">
                                                            <v-img :src="image" heigth="200" width="200">
                                                            </v-img>
                                                        </v-col>
                                                    </v-row>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-text-field label="Descripcion de Incidencia"
                                                            v-model="indicador_seleccionado.observations_incidence"
                                                            @input="indicador_seleccionado.observations_incidence = indicador_seleccionado.observations_incidence.toUpperCase()">
                                                        </v-text-field>
                                                    </v-col>
                                                </v-card>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="dialog = false">
                                                Cancelar
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="consultarAnteriorIndicador">
                                                Anterior
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="consultarSiguienteIndicador">
                                                Siguiente
                                            </v-btn>
                                        </v-card-actions>
                                    </div>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{item}">
                <v-icon color="buttonEdit" small @click="verDetalle(item)">
                    visibility
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="consultarEvaluacionesMaquinas">
                    Consultar
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog_detail" max-width="1000px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">
                        Detalle de la evaluación
                    </span>
                </v-card-title>
                <v-card-text>
                    <template>
                        <div v-if="loaderDetail">
                            <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                        </div>
                        <div v-else>
                            <v-data-table :headers="cabecera_detalle" :items="actividades_detalle" class="elevation-1">
                                <template v-slot:[`item.accionesArticulo`]="{item}">
                                    <v-icon color="buttonEdit" small @click="abrirVentanaIncidencias(item)">
                                        sms_failed
                                    </v-icon>
                                </template>
                                <template v-slot:[`item.is_correct`]="{item}">
                                    <v-chip :color="evaluarSiHuboIncidencia(item)" dark>
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer>
                    </v-spacer>
                    <v-btn text color="primary" @click="closeDialogDetail">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_detail_image" max-width="800px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">
                        Evidencia de la incidencia
                    </span>
                </v-card-title>
                <v-card-text>
                    <template>
                        <div v-if="loaderImage">
                            <v-skeleton-loader class="mx-auto" type="image">
                            </v-skeleton-loader>
                        </div>
                        <div v-else>
                            <v-row>
                                <v-col cols="4" sm="4" md="4" v-for="imagenes in detalle_actividad_imagenes"
                                    :key="imagenes.id">
                                    <v-img :src="imagenes.image" heigth="300" width="300" @click="openImage(imagenes)">
                                    </v-img>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer>
                    </v-spacer>
                    <v-btn text color="primary" @click="closeDialogDetailImage">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-overlay :value="overlayValue">
                <v-img heigth="800" width="800" :src="overlayImage" @click="closeImage">
                </v-img>
            </v-overlay>
        </v-dialog>
        <v-dialog v-model="dialog_save" max-width="500px" persistent>
            <v-card :loading="loading">
                <template slot="progress">
                    <v-progress-linear color="primary" height="7" indeterminate></v-progress-linear>
                </template>
                <v-card-title class="text-h5">¿Quieres finalizar la evaluación?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog_save = false" :disabled="disBoton">Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="guardarEvaluacion" :disabled="disBoton">OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import service from "@/core/Service.js";
export default {
    metaInfo: {
        title: 'Evaluación de Maquina'
    },
    data: () => ({
        url_ruta: "machine_evaluation",
        url_ruta_machine_document: "machine_document",
        url_ruta_machine_evaluation_get: "machine_evaluation_get",
        url_ruta_machine_evaluation_images: "machine_evaluation_images",
        url_ruta_configuration: "configuration",
        url_ruta_insert_activities_evaluation: "insert_activities_evaluation",
        color_chip: "",
        valor: true,
        overlayValue: false,
        overlayImage: null,
        dialog: false,
        dialogDelete: false,
        dialog_detail: false,
        dialog_detail_image: false,
        search: "",
        ocultarComponente: "",
        _nombreArchivo: "Agrega una imagen de la incidencia",
        archivo: [],
        url: [],
        nombreArchivos: [],
        loader: false,
        loaderDetail: false,
        loaderImage: false,
        compania: JSON.parse(localStorage.getItem("company")),
        loader_evaluation: false,
        ocultar_rangos: false,
        encabezados: [
            { text: 'Maquina', value: 'machine' },
            { text: 'Metodologia', value: 'document' },
            { text: 'Turno', value: 'turn' },
            { text: 'Pieza', value: 'piece' },
            { text: 'Cliente', value: 'name_or_business_name' },
            { text: 'Fecha de Evaluación', value: 'date_evaluation' },
            { text: 'Acciones', value: 'actions', sortable: false }
        ],
        cabecera_detalle: [
            { text: 'Actividad', value: 'activity' },
            { text: 'Categoria Actividad', value: 'description' },
            { text: 'Porcentaje Mínimo', value: 'minimum_percentage' },
            { text: 'Porcentaje Máximo', value: 'maximum_percentage' },
            { text: 'Calificación', value: 'califacation' },
            { text: 'Incidencia', value: 'is_correct' },
            { text: 'Acciones', value: 'accionesArticulo', sortable: false }
        ],
        detalles: [],
        actividades_detalle: [],
        documentos: [],
        documentos_servidor: [],
        maquinas: [],
        piezas: [],
        turnos: [],
        clientes: [],
        configuraciones: [],
        indicadores_detalles: [],
        tipos_incidencias: [],
        tipo_incidencia_seleccionada: null,
        observacion_incidencia: "",
        incidence: false,
        editedIndex: -1,
        step: 1,
        step_evaluation: 1,
        disBoton: false,
        loading: false,
        dialog_save: false,
        color_evaluacion: "yellow",
        categorias: [
            { categoria: 'RANGOS', id: 1 },
            { categoria: 'VERDADERO O FALSO', id: 2 },
            { categoria: 'DECIMALES', id: 3 },
            { categoria: 'ENTERO', id: 4 },
        ],
        editedItem: {
            machine_id: 0,
            turn_id: 0,
            client_id: 0,
            piece_id: 0,
            document_id: 0,
        },
        defaultItem: {
            machine_id: 0,
            turn_id: 0,
            client_id: 0,
            piece_id: 0,
            document_id: 0,
        },
        detalle_actividad_imagenes: {},
        indicador_seleccionado: {
            machine_evaluation_id: 0,
            code: '',
            activity: '',
            activity_category: '',
            observations: '',
            activity_category_id: 0,
            minimum_percentage: 0,
            maximum_percentage: 0,
            maximum_percentage_evaluation: 0,
            minimum_percentage_evaluation: 0,
            califacation: 0
        },
        indicador_index: 1,
        rules_validar: [(v) => !!v || "Campo requerido"],
        idMachineEvaluated: 0
    }),

    mounted() {

    },

    created() {
        this.consultarEvaluacionesMaquinas();
        this.consultarConfiguracion();
        this.consultarObjetos();
    },

    computed: {
        obtenerColorEvaluacion() {
            let color_evaluacion = "yellow";
            if (this.indicador_seleccionado != null) {
                if (this.indicador_seleccionado.activity_category_id === 1) {
                    if (this.indicador_seleccionado.califacation < this.indicador_seleccionado.minimum_percentage_evaluation || this.indicador_seleccionado.califacation > this.indicador_seleccionado.maximum_percentage_evaluation) {
                        color_evaluacion = "red";
                    } else {
                        color_evaluacion = "green";
                    }
                } else {
                    color_evaluacion = "green";
                }
            } else {
                color_evaluacion = "yellow";
            }

            return color_evaluacion;
        },

        obtenerIncidenciaEvaluacion() {
            let incidencia_visble = false;
            if (this.configuraciones[0].incidence === 0) {
                if (this.indicador_seleccionado != null) {
                    if (this.indicador_seleccionado.activity_category_id === 1) {
                        if (this.indicador_seleccionado.califacation < this.indicador_seleccionado.minimum_percentage_evaluation || this.indicador_seleccionado.califacation > this.indicador_seleccionado.maximum_percentage_evaluation) {
                            incidencia_visble = true;
                        } else {
                            incidencia_visble = false;
                        }
                    } else {
                        if (this.indicador_seleccionado.califacation == false) {
                            incidencia_visble = true;
                        } else {
                            incidencia_visble = false;
                        }
                    }
                } else {
                    incidencia_visble = false;
                }
            }
            else {
                incidencia_visble = false;
            }
            return incidencia_visble;
        },

        evidenciaVisible() {
            let visible = true;
            if (this.indicador_seleccionado.incidence_type != null) {
                if (this.indicador_seleccionado.incidence_type.evidence_required == 1) {
                    visible = false;
                } else {
                    visible = true;
                }
            } else {
                visible = true;
            }
            return visible
        },
    },

    methods: {
        consultarEvaluacionesMaquinas() {
            this.loader = true;
            service.consultarDatos(this.url_ruta).then((response) => {
                if (response.data.response_flag == 1) {
                    this.loader = false;
                    this.detalles = response.data.response;
                }
            }).catch(() => {
                this.loader = false;
                console.log("Problema al consultar evaluaciones de maquinas");
            });
        },

        consultarObjetos() {
            service.consultarDatos(this.url_ruta_machine_evaluation_get).then((response) => {
                if (response.data.response_flag === 1) {
                    this.clientes = response.data.response.clients;
                    this.turnos = response.data.response.turns;
                    this.maquinas = response.data.response.machines;
                    this.piezas = response.data.response.pieces;
                    this.tipos_incidencias = response.data.response.incidence_types;
                }
            }).catch((error) => {
                console.log("Problema al consultar los objetos" + error);
            })
        },

        consultarActividadesImagenes(id) {
            this.loaderImage = true;
            service.buscarDatos(this.url_ruta_machine_evaluation_images, id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.loaderImage = false;
                    this.detalle_actividad_imagenes = response.data.response;
                }
            })
                .catch((error) => {
                    console.log("Problema al consultar las imagenes de la incidencia" + error);
                })
        },

        consultarDetalleEvaluacion() {
            this.loaderDetail = true;
            service.buscarDatos(this.url_ruta, this.editedItem.id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.loaderDetail = false;
                    let data = response.data.response;
                    data.forEach(element => {
                        if (element.activity_category_id == 2) {
                            element.minimum_percentage = "N/A"
                            element.maximum_percentage = "N/A"
                            element.califacation = (element.is_correct == 0) ? "NO" : "SI"
                        }
                    });
                    this.actividades_detalle = data;
                }
            })
                .catch((error) => {
                    console.log("Problema al consultar el detalle" + error);
                });
        },

        consultarDocumentos(id) {
            service
                .buscarDatos(this.url_ruta_machine_document, id)
                .then((response) => {
                    if (response.data.response_flag == 1) {
                        this.documentos = response.data.response;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    console.log("Problema al consultar turnos");
                });
        },

        consultarActividadesAsigandas() {
            if (this.$refs.form_evaluation.validate()) {
                this.step = 2;
                this.loader_evaluation = true;
                this.saveEvaluationMachine();
                service.consultarActividadesAsigandas(this.editedItem.document_id).then((response) => {
                    this.loader_evaluation = false;
                    if (response.data.response_flag == 1) {
                        //Recorremos los detalles para agregarles la propiedad de evaluacion
                        response.data.response.forEach(element => {
                            element.incidence_type = null;
                            element.observations_incidence = "";
                            element.evidence_file = null;
                            if (element.activity_category_id == 1) {
                                element.califacation = element.minimum_percentage_evaluation;
                            } else {
                                element.califacation = true;
                            }
                        });

                        this.indicadores_detalles = response.data.response;
                        if (this.indicadores_detalles.length > 0) {
                            this.indicador_seleccionado = this.indicadores_detalles[0];
                            this.ocultarRangos();
                        }
                    }
                }).catch(() => {
                    this.loader_evaluation = false;
                    console.log("Problema al consultar actividades");
                });
            }
        },

        consultarSiguienteIndicador() {
            if (this.indicador_index < this.indicadores_detalles.length) {
                let detalle_posicion = this.indicadores_detalles.findIndex(element => element.id == this.indicador_seleccionado.id);
                this.indicadores_detalles[detalle_posicion].images = this.url;
                this.indicadores_detalles[detalle_posicion].base64 = this.nombreArchivos;
                this.indicadores_detalles[detalle_posicion].files = this.archivo;
                this.indicadores_detalles[detalle_posicion].machine_evaluation_id = this.idMachineEvaluated;
                this.saveEvaluationMachineActivities(this.indicadores_detalles[detalle_posicion]);
                this.url = [];
                this.nombreArchivos = [];
                this.archivo = [];
                this.indicador_seleccionado = this.indicadores_detalles[detalle_posicion + 1];
                this.indicador_index = this.indicador_index + 1;
                this.ocultarRangos();
            } else {
                let detalle_posicion = this.indicadores_detalles.findIndex(element => element.id == this.indicador_seleccionado.id);
                this.indicadores_detalles[detalle_posicion].images = this.url;
                this.indicadores_detalles[detalle_posicion].base64 = this.nombreArchivos;
                this.indicadores_detalles[detalle_posicion].files = this.archivo;
                this.indicadores_detalles[detalle_posicion].machine_evaluation_id = this.idMachineEvaluated;
                this.saveEvaluationMachineActivities(this.indicadores_detalles[detalle_posicion]);
                this.dialog_save = true;
            }
        },

        consultarAnteriorIndicador() {
            if (this.indicador_index > 1) {
                let detalle_posicion = this.indicadores_detalles.findIndex(element => element.id == this.indicador_seleccionado.id);
                this.indicador_seleccionado = this.indicadores_detalles[detalle_posicion - 1];
                this.url = this.indicador_seleccionado.images;
                this.archivo = this.indicador_seleccionado.files;
                this.nombreArchivos = this.indicador_seleccionado.base64;
                this.indicador_index = this.indicador_index - 1;
                this.ocultarRangos();
            }
        },

        consultarConfiguracion() {
            service.buscarDatos(this.url_ruta_configuration, this.compania.id).then((response) => {
                if (response.data.response_flag === 1) {
                    this.configuraciones = response.data.response;
                }
            })
                .catch((error) => {
                    console.log("Problema al consultar la configuración: " + error);
                });
        },

        ocultarRangos() {
            if (this.indicador_seleccionado.activity_category_id === 1) {
                this.rules_rangos = [(v) => !!v || "Campo requerido"];
                this.ocultar_rangos = false;
            } else {
                this.rules_rangos = [];
                this.ocultar_rangos = true;
            }
        },

        async mostrarImagen() {
            if (this.archivo !== []) {
                for (const element of this.archivo) {
                    let _url = URL.createObjectURL(element);
                    let _var = await this.convertirImageToBase64(element);
                    this.url.push(_url);
                    this.nombreArchivos.push(_var);
                }
                this.ocultarComponente = "";
            }
            else {
                this.url = [];
            }
        },

        hideComponente() {
            this.ocultarComponente = "display: none;"
            this.url = [];
        },

        convertirBase64ToImage(base64, nombreArchivo) {
            this.url = base64;
            this._nombreArchivo = nombreArchivo;
        },

        convertirImageToBase64(file) {
            return new Promise(function (resolve, reject) {
                let reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },

        evaluarMinimoPorcentaje(item) {
            if (item.minimum_percentage > 0) {
                return item.minimum_percentage;
            }
            else {
                return item.minimum_percentage = "N/A";
            }
        },

        evaluarMaximoPorcentaje(item) {
            if (item.maximum_percentage > 0) {
                return item.maximum_percentage;
            }
            else {
                return item.maximum_percentage = "N/A";
            }
        },

        evaluarSiHuboIncidencia(item) {
            if (item.is_correct == 0) {
                return "red"
            } else {
                return "green"
            }
        },

        verDetalle(item) {
            this.editedItem = Object.assign({}, item);
            this.consultarDetalleEvaluacion();
            this.dialog_detail = true;
        },

        closeDialogDetail() {
            this.dialog_detail = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
            });
        },

        closeDialogDetailImage() {
            this.detalle_actividad_imagenes = [];
            this.dialog_detail_image = false;
        },

        abrirVentanaIncidencias(item) {
            this.consultarActividadesImagenes(item.id);
            this.dialog_detail_image = true;
        },

        openImage(item) {
            this.overlayImage = item.image;
            this.overlayValue = true;
        },

        closeImage() {
            this.overlayImage = null;
            this.overlayValue = false;
        },

        guardarEvaluacion(){
            this.url = [];
            this.nombreArchivos = [];
            this.archivo = [];
            this.editedItem = Object.assign({}, this.defaultItem);
            this.indicador_seleccionado = [];
            this.indicadores_detalles = [];
            this.dialog_save = false;
            this.dialog = false;
            this.consultarEvaluacionesMaquinas();
        },

        async saveEvaluationMachineActivities(actividad) {
            service.ingresarDatos(this.url_ruta_insert_activities_evaluation, actividad).then((response) => {
                if (response.data.response_flag === 1) {
                    return response.data.response;
                }
            }).catch((error) => {
                console.log("Problema al insertar la actividad de la evaluación de la máquina" + error);
            });
        },

        async saveEvaluationMachine() {
            service.ingresarDatos(this.url_ruta, this.editedItem).then((response) => {
                if (response.data.response_flag === 1) {
                    this.idMachineEvaluated = response.data.response;
                }
            }).catch((error) => {
                console.log("Problema al agregar la evaluación de la máquina: " + error);
            });
        }
    },
}
</script>